import React, { useState } from "react";
import "./AsideNavigation.scss";
import { NavLink } from "react-router-dom";
import usersIcon from "../../assets/img/userIcon.svg";
import categoriesIcon from "../../assets/img/categoriesIcon.svg";
import stopIcon from "../../assets/img/stopIcon.svg";
import adsIcon from "../../assets/img/adsIcon.svg"
import postComments from "../../assets/img/postComments.svg"
import backgroundIcon from "../../assets/img/backgroundIcon.svg"
import routes from "../../routes";
import expandIcon from "../../assets/img/admin/expand-icon.svg";
import chatIcon from "../../assets/img/makeIcon.svg";

export default function AsideNavigation() {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    expanded ? setExpanded(false) : setExpanded(true);
  };

  return (
    <aside className="aside">
      <ul className="aside__list">
        <NavLink
          to={routes.users}
          className="aside__list-item"
          activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={usersIcon} alt="users" />
          </div>
          <span className="aside__list-name">Users</span>
        </NavLink>

        <NavLink
          to={routes.posts}
          className="aside__list-item"
          activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={postComments} alt="posts and comments" />
          </div>
          <span className="aside__list-name">Posts {"&"} Comments</span>
        </NavLink>

        <NavLink
            to={routes.backgroundEdit}
            className="aside__list-item"
            activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={backgroundIcon} alt="posts and comments" />
          </div>
          <span className="aside__list-name">Posts background</span>
        </NavLink>
        <NavLink
          to={routes.categories}
          className="aside__list-item"
          activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={categoriesIcon} alt="categories" />
          </div>
          <span className="aside__list-name">Categories</span>
        </NavLink>

        <button
          type="button"
          className={`aside__list-item ${
            expanded && "aside__list-item--active"
          }`}
          onClick={handleExpand}
        >
          <div className="aside__list-image">
            <img src={stopIcon} alt="stop" />
          </div>
          <span className="aside__list-name">Stop List</span>
          <img className="aside__expandIcon" src={expandIcon} alt="expand" />
        </button>
        {expanded && (
          <>
            <NavLink
              to={routes.stopList}
              className="aside__list-item"
              activeClassName={"aside__list-item--active"}
            >
              <span className="aside__list-name">Stop Words</span>
            </NavLink>
            <NavLink
              to={routes.violationList}
              className="aside__list-item"
              activeClassName={"aside__list-item--active"}
            >
              <span className="aside__list-name">Violation Categories</span>
            </NavLink>
          </>
        )}
        <NavLink
          to={routes.adds}
          className="aside__list-item"
          activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={adsIcon} alt="ads" />
          </div>
          <span className="aside__list-name">Ads</span>
        </NavLink>
        <NavLink
          to={routes.createPost}
          className="aside__list-item"
          activeClassName={"aside__list-item--active"}
        >
          <div className="aside__list-image">
            <img src={chatIcon} alt="testimony" />
          </div>
          <span className="aside__list-name">Make a new testimony</span>
        </NavLink>
      </ul>
    </aside>
  );
}
