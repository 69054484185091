import React, { useState, useEffect } from 'react'
import './background.scss'
import { connect } from 'react-redux'
import routes from '../../routes'
import { Navigation } from '../../components'
import shareBackground from '../../assets/img/shareBackground.svg'
import axios from 'axios'
import { api_url } from '../../config'
import { useSnackbar } from 'notistack'
import ClipLoader from 'react-spinners/ClipLoader'

const CardBackground = ({ img, setNewImg, id }) => {
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setNewImg(e.target.files?.[0], reader.result)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <div className='cardItem'>
      <p>Title here</p>
      <img src={img} alt='' className='mainPicture' />
      <label htmlFor={`upload-photo${id}`}>
        <img src={shareBackground} alt='' />
      </label>
      <input
        type='file'
        alt='photo'
        style={{ display: 'none' }}
        id={`upload-photo${id}`}
        accept='image/*'
        onChange={onChangePicture}
      />
    </div>
  )
}

function Background() {
  const [isLoading, setIsLoading] = useState(true)
  const [backgrounds, setBackgrounds] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const token = axios.defaults.headers.common.Authorization

  const getData = () =>
    axios
      .get(`${api_url}/background`)
      .then((res) => setBackgrounds(res?.data?.data))
      .finally(() => setIsLoading(false))

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token])

  const setNewImg = (id) => (file, url) => {
    setBackgrounds(
      backgrounds?.map((item) =>
        item?.background_id === id
          ? {
              background_id: id,
              order: id,
              url,
              uploadImage: file,
            }
          : item
      )
    )
  }

  const handleClickButton = (action) => {
    const newBackgrounds = backgrounds?.filter((item) => item?.uploadImage)
    if (!newBackgrounds?.length && action === 'save')
      return enqueueSnackbar('Error! Change at least one background.', {
        variant: 'error',
      })
    else if (!newBackgrounds?.length) return
    else if (action === 'save') {
      setIsLoading(true)
      const promises = []

      newBackgrounds.forEach((item) => {
        const file = new FormData()
        file.append('file', item.uploadImage)
        promises.push(
          axios.post(`${api_url}/admin/background/${item.background_id}`, file)
        )
      })
      Promise.all(promises)
        .then(() => {
          setBackgrounds(
            backgrounds?.map((item) => ({
              background_id: item?.background_id,
              order: item?.order,
              url: item?.url,
            }))
          )
          enqueueSnackbar('Success', { variant: 'success' })
        })
        .catch(() => enqueueSnackbar('Error!', { variant: 'error' }))
        .finally(() => setIsLoading(false))
    } else if (action === 'cancel') {
      getData()
    }
  }

  return (
    <div className='container background__container'>
      <div className='wrapBtn'>
        <button
          onClick={() => handleClickButton('cancel')}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button onClick={() => handleClickButton('save')} disabled={isLoading}>
          Save
        </button>
      </div>
      <div className='wrap'>
        <Navigation
          prevPage='Background'
          prevRoute={routes.backgroundEdit}
          currentPage='Background-edit'
        />
        <div className='wrapCard'>
          {!isLoading || backgrounds?.length ? (
            backgrounds?.map((item) => (
              <CardBackground
                key={item?.background_id}
                id={item?.background_id}
                img={item?.url}
                setNewImg={setNewImg(item?.background_id)}
              />
            ))
          ) : (
            <ClipLoader color={'#050138'} loading={true} size={75} />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(Background)
