import React, { useEffect, useState } from "react";
import { ModalWithButtons } from "../../components";
import axios from "axios";
import { api_url } from "../../config";
import routes from "../../routes";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Favourites, FeedView } from "../../views";
import NeedReview from "./NeedReview";
import Stream from "./Stream";

export default function Posts({ userId }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [postsPage, setPostsPage] = useState(1);
  const [activePost, setActivePost] = useState(null);
  const [activeComment, setActiveComment] = useState(null);
  const [deletePostModalIsOpen, setDeletePostModalIsOpen] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);
  const [deletedPosts, setDeletedPosts] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(1);

  const location = useLocation();

  const loadMorePosts = () => {
    if (nextPageUrl && !loading) {
      setPostsPage((prevState) => prevState + 1);
    }
  };

  const handleRevertDeletions = () => {
    setDeletedPosts([]);
    deletedPosts.forEach((id) => {
      axios
        .post(`${api_url}/admin/albums/deleted`, {
          id,
          is_deleted: 0,
        })
        .then((response) => handleGetNewPosts());
    });
  };

  const handleDeletePostModalOpen = (id) => {
    setDeletePostId(id);
    setDeletePostModalIsOpen(true);
  };

  const handleDeletePostModalClose = () => {
    setDeletePostModalIsOpen(false);
  };

  const handleGetNewPosts = () => {
    setPosts([]);
    setPostsPage(1);
    setShouldUpdate((prevState) => prevState + 1);
  };

  const handleDeletePost = () => {
    setDeletedPosts((prevState) => [...prevState, deletePostId]);
    setPosts((prevState) =>
      prevState.filter((post) => post.id !== deletePostId)
    );

    setDeletePostModalIsOpen(false);
    axios
      .post(`${api_url}/admin/albums/deleted`, {
        id: deletePostId,
        is_deleted: 1,
      })
      .then((response) => console.log("dekete", response.data));
  };

  const token = axios.defaults.headers.common.Authorization;

  const history = useHistory();

  useEffect(() => {
    setPosts([]);
    setLoading(false);
    setNextPageUrl(null);
    setPostsPage(1);
    setActivePost(null);
    setActiveComment(null);
    setDeletePostModalIsOpen(false);
  }, [location]);

  useEffect(() => {
    setLoading(true);
    if (token) {
      axios
        .get(`${api_url}/admin/albums?page=${postsPage}&is_deleted=0`)
        .then((response) => {
          location.pathname === routes.posts &&
            history.push(routes.posts + "/feedView");
          setLoading(true);
          if (posts && nextPageUrl) {
            setPosts((prevState) => [
              ...prevState,
              ...response.data.albums.data,
            ]);
          } else {
            setPosts(response.data.albums.data);
          }
          setLoading(false);
          setNextPageUrl(response.data.albums.next_page_url);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [token, postsPage, location, shouldUpdate]);

  const handleActivePost = (id) => {
    setActivePost(id);
    setActiveComment(null);
  };

  const handleActiveComment = (id) => {
    setActiveComment(id);
    setActivePost(null);
  };

  return (
    <>
      <div className="container home__container">
        <div className="wrap home__wrap">
          {deletedPosts.length > 0 && (
            <button
              className="link revert-button"
              onClick={handleRevertDeletions}
            >
              Restore deleted post(s)
            </button>
          )}
          <div className="tabs">
            {/* <Redirect path={routes.posts + "/feedView"} /> */}
            <NavLink
              className="tab"
              activeClassName="tab--active"
              to={routes.posts + "/feedView"}
            >
              Feed view
            </NavLink>
            <NavLink
              className="tab"
              activeClassName="tab--active"
              to={routes.posts + "/favourites"}
            >
              Favorites
            </NavLink>
            <NavLink
              className="tab"
              activeClassName="tab--active"
              to={routes.posts + "/needReview"}
            >
              Need Review
            </NavLink>
            <NavLink
              className="tab"
              activeClassName="tab--active"
              to={routes.posts + "/live-stream"}
            >
              Live Streams
            </NavLink>
          </div>
          <Switch>
            <Route
              exact
              path={routes.posts + "/feedView"}
              render={() => (
                <FeedView
                  posts={posts}
                  handleActivePost={handleActivePost}
                  activeComment={activeComment}
                  handleActiveComment={handleActiveComment}
                  activePost={activePost}
                  loading={loading}
                  nextPageUrl={nextPageUrl}
                  loadMorePosts={loadMorePosts}
                  userId={userId}
                  handleDeletePostModalOpen={handleDeletePostModalOpen}
                />
              )}
              // component={FeedView}
            />
            <Route
              exact
              path={routes.posts + "/favourites"}
              render={() => (
                <Favourites
                  posts={posts}
                  handleActivePost={handleActivePost}
                  activeComment={activeComment}
                  handleActiveComment={handleActiveComment}
                  activePost={activePost}
                  loading={loading}
                  nextPageUrl={nextPageUrl}
                  loadMorePosts={loadMorePosts}
                  userId={userId}
                />
              )}
            />

            <Route
              exact
              path={routes.posts + "/needReview"}
              render={() => (
                <NeedReview
                  posts={posts}
                  handleActivePost={handleActivePost}
                  activeComment={activeComment}
                  handleActiveComment={handleActiveComment}
                  activePost={activePost}
                  loading={loading}
                  nextPageUrl={nextPageUrl}
                  loadMorePosts={loadMorePosts}
                  handleDeletePostModalOpen={handleDeletePostModalOpen}
                  userId={userId}
                />
              )}
            />
            <Route
              exact
              path={routes.posts + "/live-stream"}
              render={() => (
                <Stream />
              )}
            />
          </Switch>
          <div
            style={{
              width: "1000px",
              // flexBasis: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {location.pathname === routes.posts && (
              <ClipLoader color={"#050138"} loading={loading} size={75} />
            )}
          </div>
        </div>
      </div>
      <ModalWithButtons
        modalIsOpen={deletePostModalIsOpen}
        onCloseModal={handleDeletePostModalClose}
        header="Delete Post"
        text="Are you sure you want to delete a post? This action cannot be reverted."
        onConfirm={handleDeletePost}
      />
    </>
  );
}
