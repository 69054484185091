import React from "react";
import "./Table.scss";
import delelteIcon from "../../assets/img/admin/Delete.svg";
import neutralCheckbox from "../../assets/img/admin/neutral-checkbox.svg";
import Checkbox from "react-custom-checkbox";
import checkedIcon from "../../assets/img/checked.svg";

export default function StopTable({
  data,
  handleDeleteModalOpen,
  handleCheckboxStatusChange,
  checked,
  clearSelected,
  page,
}) {
  return (
    <div>
      <table className="table">
        <thead>
          <tr className="table__headers">
            <th>
              <div
                style={{ display: "flex", cursor: "pointer" }}
                onClick={clearSelected}
              >
                <img src={neutralCheckbox} alt="neutral checkbox" />
              </div>
            </th>
            <th>#</th>
            <th>Stop word(s)</th>
            <th>Violation Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element, i) => {
            console.log("el", element);
            return (
              <tr key={element.id}>
                <td>
                  <Checkbox
                    onChange={(e) => handleCheckboxStatusChange(e, element.id)}
                    checked={checked.includes(element.id)}
                    borderColor="#C2C9D1"
                    borderRadius={4}
                    icon={
                      <div>
                        <img
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          src={checkedIcon}
                          alt="checked"
                        ></img>
                      </div>
                    }
                  />
                </td>
                <td style={{ wordBreak: "normal" }}>
                  {i + 1 + 20 * (page - 1)}
                </td>

                <td className="text text--bold">{element.name}</td>

                <td className="text text--bold">
                  {element.violation_category.name}
                </td>

                <td className="table__action">
                  <div>
                    <button
                      className="table__action-button"
                      onClick={() => handleDeleteModalOpen(element.id)}
                    >
                      <img src={delelteIcon} alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
