import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import closeIcon from "../../assets/img/close-modal.svg";

Modal.setAppElement("#root");

export default function ModalWithButton({
  modalIsOpen,
  onCloseModal,
  text,
  textTwo,
  header,
  onCancel,
  onConfirm,
  modalWidth,
  textWidth,
}) {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginLeft: 140 + "px",
      transform: "translate(-50%, -50%)",
      paddingLeft: "0",
      paddingRight: "0",
      paddingBottom: "0",
      paddingTop: "16px",
      width: modalWidth + "px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
      overflow: "hidden",
    },
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <div className="modal__top">
          <p className="text text--normal text--bold modal__header">{header}</p>
          <button onClick={onCloseModal} className="modal__close">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="modal__body">
          <p style={{ width: textWidth + "px" }} className="modal__paragraph">
            {text}
          </p>
          {textTwo && (
            <p style={{ width: textWidth + "px" }} className="modal__paragraph">
              {textTwo}
            </p>
          )}
        </div>
        <div className="modal__bottom">
          <button
            className="link link--small modal__cancel"
            onClick={onCloseModal}
          >
            Cancel
          </button>
          <button className="link link--small modal__ok" onClick={onConfirm}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
}
