import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/img/close-modal.svg";
import routes from "../../routes";
import {
  Navigation,
  ModalWithButtons,
  Button,
  EmptyModal,
} from "../../components";
import axios from "axios";
import { api_url } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import "./Categories.scss";
import UsersCategoriesTable from "../../components/Table/UserCategoriesTable";
import AdminCategoriesTable from "../../components/Table/AdminCategoriesTable";
import sortIcon from "../../assets/img/admin/sort-icon.svg";
import { useFormik } from "formik";
import createCategorySchema from "../../validationSchemas/createCategorySchema";

export default function Categories() {
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [activeTab, setActiveTab] = useState("admin");
  const [adminCategories, setAdminCategories] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [showSortHint, setShowSortHint] = useState(false);
  const [sortBy, setSortBy] = useState("recent");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submited, setSubmited] = useState(true);
  const [createError, setCreateError] = useState("");

  const token = axios.defaults.headers.common.Authorization;

  const handleDeleteModalOpen = (id = null) => {
    setDeleteId(id);
    setDeleteModalIsOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteId(null);
    setDeleteModalIsOpen(false);
  };

  const handleDeleteCategory = () => {
    axios.delete(`${api_url}/admin/categories/remove/${deleteId}`);
    handleDeleteModalClose();
    if (activeTab === "admin") {
      setAdminCategories((prevState) =>
        prevState.filter((el) => el.id !== deleteId)
      );
    } else {
      setUserCategories((prevState) =>
        prevState.filter((el) => el.id !== deleteId)
      );
    }
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage((prevState) => prevState + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1);
    }
  };

  useEffect(() => {
    setCreateError("");
    setSubmited(false);
  }, [modalIsOpen]);

  useEffect(() => {
    setUserCategories([]);
    setPage(1);
  }, [sortBy]);

  useEffect(() => {
    setShowSortHint(false);
    if (token) {
      setLoading(true);
      axios
        .get(`${api_url}/admin/categories?page=${page}&orderBy=${sortBy}`)
        .then((response) => {
          console.log("users", response.data);
          setLoading(false);
          setUserCategories(response.data.categories.data);
          setNextPageUrl(response.data.categories.next_page_url);
          setTotal(response.data.categories.total);
        })
        .catch((err) => console.log(err.response.data));
    }
  }, [token, page, activeTab, sortBy]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get(`${api_url}/categories/admin`)
        .then((response) => {
          setLoading(false);
          setAdminCategories(response.data.categories);
        })
        .catch((err) => console.log(err.response.data));
    }
    // eslint-disable-next-line
  }, [token, submited, activeTab]);

  const formError = (error, value) => {
    if ((error && value) || (error && submited) || createError) {
      return true;
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      othersInput: "",
    },
    validationSchema: createCategorySchema,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append("name", values.othersInput);
      axios
        .post(`${api_url}/admin/categories/create`, formdata)
        .then((response) => {
          console.log(response.data);
          setModalIsOpen(false);
          setSubmited(false);
        })
        .catch((err) => {
          setCreateError(err.response.data.name[0]);
        });
    },
  });

  return (
    <div className="container users">
      <div className="wrap categories__wrap">
        <Navigation
          prevPage="Categories"
          currentPage={
            activeTab === "user" ? "User Categories" : "Admin categories"
          }
          prevRoute={routes.categories}
        />
        <div className="tabs categories__tabs">
          <button
            onClick={() => setActiveTab("admin")}
            type="button"
            className={`tab ${activeTab === "admin" && "tab--active"}`}
            to={routes.posts + "/feedView"}
          >
            Admin Categories
          </button>
          <button
            type="button"
            onClick={() => setActiveTab("user")}
            className={`tab ${activeTab === "user" && "tab--active"}`}
            activeClassName="tab--active"
            to={routes.posts + "/favourites"}
          >
            User Categories
          </button>
        </div>
        {activeTab === "admin" && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <AdminCategoriesTable
              data={adminCategories}
              handleDeleteModalOpen={handleDeleteModalOpen}
            />
            <Button
              text="Add new category"
              className="button button--primary"
              onClick={() => setModalIsOpen(true)}
            />
          </div>
        )}
        {activeTab === "user" && (
          <>
            <div className="searchPannel categories__searchPannel">
              <button
                className="categories__sort"
                onClick={() => setShowSortHint(true)}
              >
                <img src={sortIcon} alt="sort" style={{ marginRight: "5px" }} />
                <span className="link text--bold text--small">Sort by</span>
              </button>
              {showSortHint && (
                <div className="post__actions-hint">
                  <button
                    className="post__actions-hintClose"
                    onClick={() => setShowSortHint(false)}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                  <div
                    onClick={() => {
                      setShowSortHint(false);
                      setSortBy("albums_count");
                    }}
                    className="post__actions-hintElement"
                  >
                    Popular
                  </div>
                  <div
                    onClick={() => {
                      setShowSortHint(false);
                      setSortBy("recent");
                    }}
                    className="post__actions-hintElement"
                  >
                    Recent
                  </div>
                </div>
              )}
            </div>

            {userCategories && !loading && userCategories.length > 0 ? (
              <UsersCategoriesTable
                data={userCategories}
                handleDeleteModalOpen={handleDeleteModalOpen}
              />
            ) : (
              !loading && (
                <h3
                  className="header--three text--center"
                  style={{ marginTop: "50px" }}
                >
                  No users found
                </h3>
              )
            )}

            {!loading && (
              <div
                className="adds__pagination"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {page > 1 ? 20 * (page - 1) + 1 : 1}-{20 * page} of {total}{" "}
                  Items
                  <button
                    type="button"
                    className={`adds__pagination-button ${
                      page < 2 && "disabled"
                    }`}
                    onClick={handlePrevPage}
                  >
                    {"<"}
                  </button>
                  <button
                    type="button"
                    className={`adds__pagination-button ${
                      !nextPageUrl && "disabled"
                    }`}
                    onClick={handleNextPage}
                  >
                    {">"}
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={"#050138"} loading={loading} size={75} />
        </div>
      </div>

      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete category"
        text="Are you sure you want to delete a category? This action cannot be reverted."
        onConfirm={handleDeleteCategory}
      />
      <EmptyModal
        modalIsOpen={modalIsOpen}
        onCloseModal={() => {
          setModalIsOpen(false);
          setSubmited(false);
        }}
        header="New category"
        buttonFor="create-category-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className="text text--center">
            Enter a category name and press Save to add it to the system.
          </p>
          <form
            id="create-category-form"
            onSubmit={(values) => {
              setSubmited(true);
              formik.handleSubmit(values);
            }}
          >
            <div className="form__group">
              <span className="formNotification text--bold adds__label">
                Category
              </span>
              <input
                type="text"
                className={`formInput ${
                  formError(
                    formik.errors.othersInput,
                    formik.values.othersInput
                  ) && "inputError"
                }`}
                placeholder="Enter a category name"
                name="othersInput"
                onChange={(e) => {
                  formik.handleChange(e);
                  setCreateError("");
                }}
                onBlur={formik.handleBlur}
              />
              <p className="formNotification">
                {formError(formik.errors.othersInput, formik.values.othersInput)
                  ? formik.errors.othersInput || createError
                  : "Enter category name in the field above."}
              </p>
            </div>
          </form>
        </div>
      </EmptyModal>
    </div>
  );
}
