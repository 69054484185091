import React, { Component } from "react";
import Modal from "react-modal";
import "./Modal.scss";

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    marginLeft: 140 + "px",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0px",
    paddingTop: "16px",
    width: "568px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    overflow: "unset",
  },
};

export default class EmptyModal extends Component {
  render() {
    const {
      modalIsOpen,
      onCloseModal,
      onConfirm = null,
      header,
      buttonFor,
    } = this.props;
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <div className="modal__top">
          <h3 className="modal__header">{header}</h3>
        </div>
        <div className="modal__body emptyModal__body">
          {this.props.children}
        </div>
        <div className="modal__bottom">
          <button
            className="link link--small modal__cancel"
            onClick={onCloseModal}
          >
            Cancel
          </button>
          {buttonFor && (
            <button
              type="submit"
              form={buttonFor}
              className="link link--small modal__ok"
              onClick={onConfirm}
            >
              Save
            </button>
          )}
        </div>
      </Modal>
    );
  }
}
