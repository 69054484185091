import React, { Component } from "react";
import Modal from "react-modal";
import Avatar from "react-avatar-edit";
import Button from "../Button";
import closeIcon from "../../assets/img/close-modal.svg";

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "96px",
    width: "430px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    overflow: "hidden",
  },
  labelStyle: {
    padding: "10px 20px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ffffff",
    borderRadius: "4px",
    letterSpacing: "1.25px",
    transition:
      "background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.1s ease-out",
    background: "#2e8299",
    cursor: "pointer",
    height: "36px",
    position: "absolute",
    bottom: "31px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

Modal.setAppElement("#root");

export default class AvatarChange extends Component {
  state = {
    preview: "",
  };

  componentDidUpdate() {
    customStyles.labelStyle.display = "flex";
  }

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
  };

  onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1171680) {
      alert("File is too big!");
      elem.target.value = "";
    }
    customStyles.labelStyle.display = "none";
  };
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyles}
        onRequestClose={this.props.onClose}
      >
        <div className="changeAvatar">
          <div className="changeAvatar__head">
            <p className="text text--normal text--bold changeAvatar__header">
              Select Profile Photo
            </p>
            <button
              onClick={this.props.onClose}
              className="changeAvatar__close"
            >
              <img src={closeIcon} alt="close" />
            </button>
          </div>

          <hr />
          <div className="changeAvatar__wrap">
            <p className="text--small changeAvatar__text">
              To crop the image, drag the region below and then click{" "}
              <span className="text--bold">“Save”</span>.
            </p>
            <Avatar
              width={156}
              height={207}
              onCrop={this.onCrop}
              onClose={this.onClose}
              labelStyle={customStyles.labelStyle}
              shadingColor="#FFFFFF"
              shadingOpacity="0.7"
              closeIconColor="transparent"
              cropColor="#050138"
              onBeforeFileLoad={this.onBeforeFileLoad}
              onFileLoad={this.props.setImageLoaded}
              onImageLoad={(image) => {
                console.log("image", image);
              }}
            />
          </div>
        </div>
        {this.props.imageLoaded && (
          <div className="changeAvatar__buttons">
            <Button
              text="Cancel"
              className="button button--outlined changeAvatar__cancel"
              onClick={this.props.onClose}
            />

            <Button
              outlined="false"
              text="Save"
              className="button button--primary"
              onClick={() => {
                this.props.onSave(this.state.preview);
              }}
            />
          </div>
        )}
      </Modal>
    );
  }
}
