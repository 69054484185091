import React, { useState, useEffect } from 'react'
import './auth.scss'
import { Button, ModalWindow, PasswordInput } from '../../components'
import authOperations from '../../redux/auth/authOperations'
import { Authenticator, translations } from '@aws-amplify/ui-react'
import { I18n, Amplify, Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_mXRmFrA16',
    userPoolWebClientId: '1bcn0m0sp9ko7385nqb2ookmjc',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})
I18n.putVocabularies(translations)
I18n.setLanguage('en')
function LogIn({ onRememberLogin, authError, clearErrors, getUser }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modal, setModal] = useState({
    header: '',
    text: '',
  })

  useEffect(() => {
    return () => {
      clearErrors()
    }
  }, [clearErrors])

  useEffect(() => {
    if (authError === 'Unauthorized') {
      setModal({ text: 'Invalid credentials.', header: 'Error' })
      setModalIsOpen(true)
    } else if (authError === 'not confirmed') {
      setModal({
        text: 'Check out your email to confirm registration.',
        header: 'Need confirmation',
      })
      setModalIsOpen(true)
    }
  }, [authError])
  I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in', // Tab header
  })

  const onCloseModal = () => {
    setModalIsOpen(false)
  }

  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData
      username = username.toLowerCase()

      return Auth.signIn({
        username,
        password,
      }).then((user) => {
        console.log('us', user)
        onRememberLogin({
          token: user.signInUserSession.idToken.jwtToken,
          confirmed: true,
        })
        getUser()
        return user
      })
    },
  }

  return (
    <div className="container auth__container">
      <div className="wrap">
        <h1 className="header header--one auth__header--one">
          Testigram Admin Panel
        </h1>
        <p className="subtitle auth__subtitle">
          Log into the system to manage users, posts {'&'} comments, categories
          and ads.
        </p>
        <Authenticator
          loginMechanisms={['email']}
          signInAttributes={['email']}
          hideSignUp
          services={services}
        />
      </div>
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header={modal.header}
        text={modal.text}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: authSelectors.getError(state),
  }
}

export default connect(mapStateToProps, {
  clearErrors: authOperations.clearErrors,
  onRememberLogin: authOperations.rememberMeLogIn,
  getUser: authOperations.getCurrentUser,
})(LogIn)
