import * as Yup from "yup";

import values from "./schemaValues";

const { stopWord } = values;

const createStopWordSchema = Yup.object({
  stopWord,
});

export default createStopWordSchema;
