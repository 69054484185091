import React from "react";
import { PostsList, Navigation } from "../../components";
import ClipLoader from "react-spinners/ClipLoader";
import routes from "../../routes";
import useInfiniteScroll from "../../utils/useInfiniteScroll";
import refreshIcon from "../../assets/img/admin/refresh-icon.svg";

export default function FeedView({
  posts,
  handleActivePost,
  activeComment,
  handleActiveComment,
  activePost,
  loading,
  userId,
  nextPageUrl,
  loadMorePosts,
  handleDeletePostModalOpen,
}) {
  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMorePosts : () => {},
    loading
  );

  return (
    <>
      {!loading && (
        <a href="#root" className="refresh-button">
          <img src={refreshIcon} alt="refresh" />
        </a>
      )}
      <Navigation
        prevPage={`Post ${"&"} Comments`}
        currentPage="Feed View"
        prevRoute={routes.posts}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "50px",
        }}
      >
        {posts && posts.length > 0 && (
          <>
            <h4
              style={{
                marginBottom: "19px",
                width: "723px",
                textAlign: "center",
              }}
              className="header header--four"
            >
              Feed public view
            </h4>
            <PostsList
              posts={posts}
              userId={userId}
              handleActivePost={handleActivePost}
              activePost={activePost}
              activeComment={activeComment}
              handleActiveComment={handleActiveComment}
              handleDeletePostModalOpen={handleDeletePostModalOpen}
            />
          </>
        )}
        <span className="profile__lastElement" ref={lastElementRef}></span>
      </div>

      <div
        style={{
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {posts && posts.length > 0 && (
          <h4 className="header header--four">Admin actions</h4>
        )}
      </div>
      {loading && (
        <div
          style={{
            width: "1000px",
            flexBasis: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ClipLoader color={"#050138"} loading={loading} size={75} />
        </div>
      )}
    </>
  );
}
