import axios from "axios";
import { api_url } from "../../config";
import postActions from "./postActions";

const addText = (text) => (dispatch) => {
  dispatch(postActions.addText(text));
};

const addTitle = (title) => (dispatch) => {
  dispatch(postActions.addTitle(title));
};

const addFullName = (fullName) => (dispatch) => {
  dispatch(postActions.addFullName(fullName));
};

const addFile = (file) => (dispatch) => {
  console.log("operations file", file);
  dispatch(postActions.addFile(file));
};

const addCategory = (category) => (dispatch) => {
  dispatch(postActions.addCategory(category));
};

const addStyle = (style) => (dispatch) => {
  dispatch(postActions.addStyle(style));
};

const clearState = () => (dispatch) => {
  dispatch(postActions.clearState());
};

const getStopWords = () => (dispatch) => {
  axios
    .get(`${api_url}/stop-words`)
    .then((response) => dispatch(postActions.getStopWords(response.data)))
    .catch((err) => console.log(err));
};

const clearBackground = () => (dispatch) => {
  dispatch(postActions.clearBackground());
};

const getBackgroundsList = () => (dispatch) => {
  axios
      .get(`${api_url}/background`)
      .then((response) => dispatch(postActions.backgroundsList(response?.data?.data)))
      .catch((err) => console.log(err));
};

const addDefaultBackground = (background_id) => (dispatch) => {
  dispatch(postActions.addDefaultBackground(background_id));
};


const postOperations = {
  addText,
  addFile,
  addCategory,
  addStyle,
  clearState,
  getStopWords,
  addTitle,
  addFullName,
  getBackgroundsList,
  addDefaultBackground,
  clearBackground,
};

export default postOperations;
