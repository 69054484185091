import React, { useState, useEffect } from 'react'
import { Navigation, Button, ModalWindow, Status } from '../../components'
import './Adds.scss'
import { api_url } from '../../config'
import { useFormik } from 'formik'
import createAddSChema from '../../validationSchemas/createAddSchema'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import routes from '../../routes'
import ClipLoader from 'react-spinners/ClipLoader'
import ReactQuill from 'react-quill'

export default function EditAdd() {
  const [submited, setSubmited] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formInputFocused, setFormInputFocused] = useState(false)
  const [adData, setAdData] = useState({
    title: '',
    conversion_rate: '0',
    description: '',
    url: '',
    file: '',
    created_at: '',
  })

  const history = useHistory()

  const { id } = useParams()

  const handleCloseModal = () => {
    setModalIsOpen(false)
    history.push(routes.adds)
  }

  const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    setLoading(true)
    if (token) {
      axios
        .get(`${api_url}/admin/advertisements/${id}`)
        .then((response) => {
          setAdData({
            ...response.data.advertisement,
            url: response.data.advertisement?.url || '',
            file: response.data.advertisement?.media?.[0]?.url || '',
          })
        })
        .catch(() => history.push('/notFound'))
        .finally(() => setLoading(false))
    }
  }, [token, id, history])

  const formik = useFormik({
    initialValues: {
      addsName: adData.title ? adData.title : 'Title',
      addsDescription: adData.description || '',
      addsUrl: adData.url || '',
    },
    enableReinitialize: true,
    isInitialValid: true,
    validateOnMount: true,
    validationSchema: createAddSChema,

    onSubmit: (values) => {
      if (
        !loading &&
        (adData.title !== values.addsName ||
          adData.description !== values.addsDescription ||
          adData.url !== values.addsUrl)
      ) {
        setLoading(true)
        const formdata = new FormData()
        formdata.append('id', id)
        formdata.append('title', values.addsName)
        formdata.append('description', values.addsDescription)
        formdata.append('conversion_rate', adData.conversion_rate)
        formdata.append('status', adData.status)
        formdata.append('url', values.addsUrl)
        axios
          .post(`${api_url}/admin/advertisements/edit`, formdata)
          .then((response) => {
            setLoading(false)
            console.log(response.data)
            setModalIsOpen(true)
          })
          .catch((err) => console.log(err))
      }
    },
  })

  const handleCancel = () => {
    if (
      adData.title !== formik.values.addsName ||
      adData.description !== formik.values.addsDescription ||
      adData.url !== formik.values.addsUrl
    ) {
      formik.resetForm()
    } else {
      history.goBack()
    }
  }

  const formError = (error, value) => {
    if ((error && value) || (error && submited)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='container'>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      ></div>
      <form
        encType='multipart/form-data'
        className='wrap createAdd__wrap'
        onSubmit={(values) => {
          setSubmited(true)
          formik.handleSubmit(values)
        }}
      >
        <Navigation
          prevPage='Ads'
          currentPage={adData.title}
          prevRoute={routes.adds}
        />
        <div
          style={{
            position: 'absolute',
            right: '0',
            top: '-23px',
            transform: 'translateY(-100%)',
          }}
        >
          <Button
            text='Cancel'
            contained={false}
            className='button button--outlined'
            onClick={handleCancel}
          />
          <button
            className='button button--primary createAdd__save'
            type='submit'
          >
            {loading ? (
              <ClipLoader color={'#ffffff'} loading={loading} size={15} />
            ) : (
              'Edit'
            )}
          </button>
        </div>
        {adData.file ? (
          <>
            <div className='adds__block'>
              <div
                style={{
                  position: 'relative',
                  width: '468px',
                  height: '468px',
                  borderRadius: '15px',
                  overflow: 'hidden',
                }}
              >
                <img
                  style={{
                    maxWidth: '100%',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  src={adData.file}
                  alt='chosen'
                  className='chooseFilter__image'
                />
              </div>

              <div className='form__group'>
                <span className='formNotification text--bold adds__label'>
                  External URL
                </span>
                <input
                  type='text'
                  className={`formInput ${
                    formError(formik.errors.addsUrl, formik.values.addsUrl) &&
                    'inputError'
                  }`}
                  name='addsUrl'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addsUrl}
                />
                <p className='formNotification'>
                  {formError(formik.errors.addsUrl, formik.values.addsUrl)
                    ? formik.errors.addsUrl
                    : 'The indicated URL will redirect users to external resource.'}
                </p>
              </div>
            </div>
            <div className='adds__block' style={{ marginLeft: '50px' }}>
              <div
                style={{
                  display: 'flex',
                  width: '522px',
                  justifyContent: 'flex-end',
                }}
              >
                <div className='adds__card'>
                  <div className='adds__card-top text--bold'>Created Date</div>
                  <div className='adds__card-bottom'>
                    {new Date(adData.created_at).toLocaleDateString('en-Us')}
                  </div>
                </div>
                <div className='adds__card'>
                  <div className='adds__card-top text--bold'>Status</div>
                  <div className='adds__card-bottom'>
                    <Status statusCode={adData.status} />
                  </div>
                </div>
              </div>
              <div className='form__group'>
                <span className='formNotification text--bold adds__label'>
                  Ads title
                </span>
                <input
                  type='text'
                  className={`formInput ${
                    formError(formik.errors.addsName, formik.values.addsName) &&
                    'inputError'
                  }`}
                  placeholder='enter ads title here'
                  name='addsName'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addsName}
                />
                <p className='formNotification'>
                  {formError(formik.errors.addsName, formik.values.addsName)
                    ? formik.errors.addsName
                    : ' Maximum title length is 70 symbols..'}
                </p>
              </div>
              <div className='form__group'>
                <span className='formNotification text--bold adds__label'>
                  Ads description
                </span>
                <ReactQuill
                  onFocus={() => setFormInputFocused(true)}
                  onBlur={() => setFormInputFocused(false)}
                  theme={'snow'}
                  name='addsDescription'
                  className={`formInput ${
                    formError(
                      formik.errors.addsDescription,
                      formik.values.addsDescription
                    ) && 'inputError'
                  } ${formInputFocused && 'formInput--focused'}`}
                  style={{ fontSize: '16px', padding: '0' }}
                  value={formik.values.addsDescription}
                  placeholder='enter ads description here'
                  onChange={(content, delta, source, editor) => {
                    formik.setFieldValue(
                      'addsDescription',
                      content
                        .replace(/<img .*?>/g, '')
                        .replace(
                          '<p><br></p><p><br></p><p><br></p>',
                          '<p><br></p>'
                        ),
                      true
                    )
                  }}
                  preserveWhitespace={true}
                />
                {/* <textarea
                  value={formik.values.addsDescription}
                  style={{ resize: "none", height: "183px" }}
                  type="text"
                  className={`formInput ${
                    formError(
                      formik.errors.addsDescription,
                      formik.values.addsDescription
                    ) && "inputError"
                  }`}
                  placeholder="enter ads description here"
                  name="addsDescription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                /> */}
                <p className='formNotification'>
                  {formError(
                    formik.errors.addsDescription,
                    formik.values.addsDescription
                  )
                    ? formik.errors.addsDescription
                    : 'Maximum description text length is 1500 symbols.'}
                </p>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ClipLoader color={'#050138'} loading={true} size={75} />
          </div>
        )}
      </form>
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        header='Updated'
        text={`Ad ${formik.values.addsName} has been successfully updated.`}
      />
    </div>
  )
}
