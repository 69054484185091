import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import closeIcon from "../../assets/img/close-modal.svg";

Modal.setAppElement("#root");

export default function ModalWindow({
  modalIsOpen,
  onCloseModal,
  text,
  header,
  textWidth,
  modalWidth,
}) {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginLeft: 140 + "px",
      transform: "translate(-50%, -50%)",
      paddingLeft: "0",
      paddingRight: "0",
      paddingBottom: "16px",
      paddingTop: "16px",
      width: modalWidth + "px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
      overflow: "hidden",
    },
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <p className="text text--normal text--bold modal__header">{header}</p>
        <button onClick={onCloseModal} className="modal__close">
          <img src={closeIcon} alt="close" />
        </button>
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ maxWidth: textWidth + "px" }}
          className="modal__text"
        ></p>
        <button className="modal__button" onClick={onCloseModal}>
          OK
        </button>
      </Modal>
    </div>
  );
}
