import React, { useState } from "react";
import playIcon from "../../assets/img/admin/play-circle.svg";
import pauseIcon from "../../assets/img/admin/pause-circle.svg";
import stopIcon from "../../assets/img/admin/stop-circle.svg";
import axios from "axios";
import { api_url } from "../../config";
import { Status } from "../../components";
import deleteIcon from "../../assets/img/admin/Delete.svg";

export default function StatusCodeButtons({
  status,
  id,
  handleDeleteModalOpen,
}) {
  const [statusCode, setStatusCode] = useState(status);

  const handleChangeStatus = (newStatus) => {
    setStatusCode(newStatus);
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", newStatus);

    axios
      .post(`${api_url}/admin/advertisements/edit`, formdata)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <td>
        <Status statusCode={statusCode} />
      </td>
      <td className="table__action">
        <div>
          <button
            onClick={() => handleChangeStatus(2)}
            disabled={statusCode === 2 || statusCode === 3}
            className={`table__action-button ${
              (statusCode === 2 || statusCode === 3) &&
              "table__action-button--disabled"
            }`}
          >
            <img src={playIcon} alt="play" />
          </button>
          <button
            onClick={() => handleChangeStatus(1)}
            disabled={statusCode === 1 || statusCode === 3}
            className={`table__action-button ${
              (statusCode === 1 || statusCode === 3) &&
              "table__action-button--disabled"
            }`}
          >
            <img src={pauseIcon} alt="pause" />
          </button>
          <button
            onClick={() => handleChangeStatus(4)}
            disabled={statusCode === 3 || statusCode === 4}
            className={`table__action-button ${
              (statusCode === 3 || statusCode === 4) &&
              "table__action-button--disabled"
            }`}
          >
            <img src={stopIcon} alt="stop" />
          </button>
          <button
            className="table__action-button"
            onClick={() => handleDeleteModalOpen(id)}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </div>
      </td>
    </>
  );
}
