import axios from 'axios'
import authActions from './authActions'
import { api_url } from '../../config'
import { Auth } from 'aws-amplify'
export const apiEndpoint =
  'https://2sxzagif0a.execute-api.us-east-1.amazonaws.com'

export const setToken = async () => {
  const session = await Auth.currentSession()
  token.set(session.getIdToken().getJwtToken())
  return `Bearer ${session.getIdToken().getJwtToken()}`
}

const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  unset() {
    axios.defaults.headers.common.Authorization = ''
  },
}
const register = ({ email, userName, fullName }) => (dispatch) => {
  dispatch(authActions.registerSuccess({ email, userName, fullName }))
}

const rememberMeLogIn = ({ confirmed, token, user }) => async (
  dispatch,
  getState,
) => {
  dispatch(
    authActions.rememberMeLogInSuccess({
      confirmed,
      token,
    }),
  )
}

const logOut = () => (dispatch) => {
  dispatch(authActions.logOutRequest())
  dispatch(authActions.logOutSuccess())
  token.unset()
}

const getCurrentUser = () => async (dispatch, getState) => {
  const token = await setToken()
  if (
    getState().auth.token &&
    getState().auth.token.token &&
    getState().auth.token.confirmed
  ) {
    const persistedToken = getState().auth.token.token
  } else {
    return
  }

  dispatch(authActions.getCurrentUserRequest())

  axios
    .get(`${api_url}/profile`, {
      headers: { Authorization: token },
    })
    .then((response) => {
      dispatch(authActions.getCurrentUserSuccess(response.data))
    })
    .catch((error) => {
      dispatch(authActions.getCurrentUserError(error))
      logOut()(dispatch)
      console.log(error.message)
    })
}

const getUserAvatar = () => async (dispatch, getState) => {
  const token = await setToken()
  if (
    getState().auth.token &&
    getState().auth.token.confirmed &&
    getState().auth.user.status !== 2
  ) {
    dispatch(authActions.getAvatarRequest())
  } else {
    return
  }

  axios
    .get(`${api_url}/profile/avatar/${getState().auth.user.id}`, {
      headers: { Authorization: token },
    })
    .then((response) => {
      dispatch(authActions.getAvatarSuccess(response.data))
    })
    .catch((error) => {
      if (error.response.data.message === 'Image does not exist')
        dispatch(authActions.getAvatarSuccess('no avatar'))
      else {
        console.log('aver', error.response.data)
        dispatch(authActions.getAvatarError(error))
      }
    })
}

const getPrevUrl = (prevUrl) => (dispatch) => {
  dispatch(authActions.getPrevUrl(prevUrl))
}

const clearPrevUrl = () => (dispatch) => {
  dispatch(authActions.clearPrevUrl())
}

const clearErrors = () => (dispatch) => {
  dispatch(authActions.clearErrors())
}

const authOperations = {
  register,
  rememberMeLogIn,
  getUserAvatar,
  logOut,
  getCurrentUser,
  clearErrors,
  getPrevUrl,
  clearPrevUrl,
  setToken,
}

export default authOperations
