const routes = {
  logIn: "/signIn",
  register: "/signUp",
  forgotPassword: "/forgotPassword",
  editProfile: "/editProfile",
  home: "/",
  profile: "/profile",
  confirmEmail: "/confirm-email",
  passwordReset: "/password-reset",
  aboutUs: "/aboutUs",
  faq: "/faq",
  privacyPolicy: "/privacyPolicy",
  termsOfService: "/termsOfService",
  createPost: "/createPost",
  chooseFile: "/chooseFile",
  chooseFilter: "/chooseFilter",
  chooseText: "/chooseText",
  chooseCategory: "/createPost",
  verifyNewEmail: "/email-set",
  singlePost: "/post",
  userProfile: "/userProfile",
  myProfile: "/myProfile",
  feed: "/feed",
  users: "/users",
  createAdd: "/createAd",
  adds: "/ads",
  editAdd: "/editAd",
  stopList: "/stopList",
  violationList: "/violationList",
  userPreview: "/userPreview",
  posts: "/posts",
  favourites: "/favourites",
  needReview: "/needReview",
  liveStream: "/live-stream",
  feedView: "/feedView",
  categories: "/categories",
  backgroundEdit: "/background-edit",
};

export default routes;
