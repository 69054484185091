import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import postReducer from "./post/postReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import thunk from "redux-thunk";

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(persistConfig, authReducer),
    post: postReducer,
  },
  middleware: [logger, thunk],
});

export const persistor = persistStore(store);
