import * as Yup from "yup";

import values from "./schemaValues";

const { postText } = values;

const editProfileSchema = Yup.object({
  postText,
});

export default editProfileSchema;
