import React, { useState } from 'react'
import logo from '../../assets/img/logo.png'
import logoMedium from '../../assets/img/logo-768.png'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import settingsIcon from '../../assets/img/admin/dropdown-icon.svg'
// import questionIcon from "../../assets/img/admin/question-icon.svg";
// import notificationIcon from "../../assets/img/admin/notification-icon.svg";
import { SettingsModal, ChangePassword } from '../../components'
import './header.scss'
import routes from '../../routes'
import Avatar from 'react-avatar'
import authOperations from '../../redux/auth/authOperations'

function Header({ isLoggedIn, fullName, avatar, onLogOut, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const handleOpenModal = () => {
    modalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true)
  }

  const handleCloseModal = (e) => {
    setModalIsOpen(false)
  }

  const handleChangePasswordOpen = () => {
    setChangePasswordOpen(true)
    handleCloseModal()
  }

  const handleChangePasswordClose = () => {
    setChangePasswordOpen(false)
  }

  return (
    <nav className="navbar navbar--dark">
      <div className="navbar__container">
        <Link
          to={routes.users}
          className={`logo navbar__logo ${
            isLoggedIn && 'navbar__logo--logged'
          } `}
        >
          <picture>
            <source media="(max-width:576px)" srcSet={logo} />
            <source media="(max-width:768px)" srcSet={logoMedium} />
            <img src={logo} alt="logo" />
          </picture>
        </Link>
        {isLoggedIn && (
          <>
            <div className="navbar__settings">
              {/* <button type="button" className="navbar__notification">
                <img src={notificationIcon} alt="notification" />
              </button>
              <button className="navbar__help">
                <img src={questionIcon} alt="question" />
              </button> */}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://testigram.ai/myProfile"
                className="navbar__avatar"
              >
                <span className="text text--normal navbar__fullName">
                  {fullName}
                </span>
                <Avatar
                  size={40}
                  name={fullName}
                  round="50%"
                  src={avatar}
                  maxInitials={2}
                  color="#2E8299"
                />
              </a>
              <button type="button" onClick={handleOpenModal}>
                <img src={settingsIcon} alt="settings" />
                <SettingsModal
                  modalIsOpen={modalIsOpen}
                  onCloseModal={handleCloseModal}
                  onLogOut={onLogOut}
                  setChangePasswordOpen={handleChangePasswordOpen}
                />
              </button>
            </div>
          </>
        )}
      </div>
      <ChangePassword
        modalIsOpen={changePasswordOpen}
        onCloseModal={handleChangePasswordClose}
      />
    </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
    fullName: authSelectors.getFullName(state),
    avatar: authSelectors.getAvatar(state),
    id: authSelectors.getId(state),
  }
}

export default connect(mapStateToProps, { onLogOut: authOperations.logOut })(
  Header,
)
