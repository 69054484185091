import React, { Component } from "react";
import { withRouter } from "react-router";
import cancelIcon from "../../assets/img/close-modal.svg";
import { Button } from "../../components";
import routes from "../../routes";
import "./createPost.scss";
import "./createPostMobile.scss";

class CreatePost extends Component {
  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleCancel = () => {
    this.props.history.push(routes.users);
  };

  render() {
    const { confirmButtonProps } = this.props;
    return (
      <>
        <div className="container createPost">
          <div className="wrap createPost__wrap">
            <button
              className="link createPost__goBack"
              onClick={this.handleCancel}
            >
              <img src={cancelIcon} alt="arrow back" />
            </button>
            <h1 className="header header--one createPost__title">
              Post new testimony
            </h1>
            {this.props.children}
            <div className="createPost__buttons">
              <Button
                {...confirmButtonProps}
                className="button button--primary createPost__next"
                onClick={this.props.onNextButton}
              />
              <Button
                text="Back"
                onClick={this.handleGoBack}
                className="button button--outlined createPost__cancel"
                contained={false}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

CreatePost.defaultProps = {
  confirmButtonProps: {
    text: "Next",
  },
};

export default withRouter(CreatePost);
