import React, { useState, useEffect } from "react";
import ViolationCategoryTable from "../../components/Table/ViolationCategoryTable";
import axios from "axios";
import { EmptyModal, ModalWithButtons, Navigation } from "../../components";
import { api_url } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import plusIcon from "../../assets/img/admin/plus-square.svg";
import "./StopList.scss";
import routes from "../../routes";
import { useFormik } from "formik";
import createViolationCategorySchema from "../../validationSchemas/createViolationCategorySchema";

export default function StopList() {
  const [words, setWords] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submited, setSubmited] = useState(false);

  const token = axios.defaults.headers.common.Authorization;

  const [checked, setChecked] = useState([]);

  const handleCheckboxStatusChange = (e, id) => {
    console.log("e", e);
    if (e) {
      setChecked((prevState) => [...prevState, id]);
    } else {
      setChecked((prevState) => [...prevState.filter((el) => el !== id)]);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api_url}/admin/violation-categories`)
      .then((response) => {
        console.log(response.data);
        setWords(response.data.categories);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [token, submited]);

  const handleDeleteModalOpen = (id = null) => {
    if (checked.length > 0 || id) {
      setDeleteId(id);
      setDeleteModalIsOpen(true);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalIsOpen(false);
  };

  const handleDeleteAd = () => {
    setDeleteModalIsOpen(false);
    if (checked && checked.length > 0 && !deleteId) {
      checked.forEach((id) => {
        axios
          .delete(`${api_url}/admin/violation-categories/remove/${id}`)
          .then(() => {
            setDeleteId(null);
            setChecked([]);
          })
          .catch((err) => console.log(err));
        setWords((prevState) => prevState.filter((el) => el.id !== id));
      });
    } else {
      axios
        .delete(`${api_url}/admin/violation-categories/remove/${deleteId}`)
        .then(() => {
          setDeleteId(null);
          setChecked([]);
        })
        .catch((err) => console.log(err));
      setWords((prevState) => prevState.filter((el) => el.id !== deleteId));
    }
  };

  const formError = (error, value) => {
    if ((error && value) || (error && submited)) {
      return true;
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      violationCategory: "",
    },
    validationSchema: createViolationCategorySchema,
    onSubmit: (values) => {
      setModalIsOpen(false);
      setSubmited(false);
      // setWords((prevState) => [
      //   ...prevState,
      //   { name: values.violationCategory, id: prevState.length + 1 },
      // ]);
      const formdata = new FormData();
      formdata.append("name", values.violationCategory);
      formdata.append("description", "violation category");
      axios
        .post(`${api_url}/admin/violation-categories/create`, formdata)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => console.log(err.response.data));
    },
  });

  return (
    <div className="container">
      <div className="wrap adds__wrap">
        <Navigation
          prevPage="Stop List"
          currentPage={"Violation Categories"}
          prevRoute={routes.violationList}
        />
        <button
          type="button"
          onClick={() => handleDeleteModalOpen()}
          className="link violationList__delete"
        >
          Delete selected
        </button>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={"#050138"} loading={loading} size={75} />
        </div>
        {words && !loading && words.length > 0 ? (
          <>
            <ViolationCategoryTable
              checked={checked}
              handleCheckboxStatusChange={handleCheckboxStatusChange}
              data={words}
              clearSelected={() => setChecked([])}
              handleDeleteModalOpen={handleDeleteModalOpen}
            />
          </>
        ) : (
          !loading && (
            <div
              style={{ marginBottom: "20px" }}
              className="header header--three text--center"
            >
              No categories added
            </div>
          )
        )}
        <button
          className="violationList__button"
          onClick={() => setModalIsOpen(true)}
        >
          <img src={plusIcon} alt="Plus" />
          <span className="table__adsName"> Add violation category</span>
        </button>
      </div>
      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete category"
        text="Are you sure you want to delete a category? This action cannot be reverted."
        onConfirm={handleDeleteAd}
      />
      <EmptyModal
        modalIsOpen={modalIsOpen}
        onCloseModal={() => {
          setModalIsOpen(false);
          setSubmited(false);
        }}
        header="New violation category"
        buttonFor="create-category-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className="text text--center">
            Enter a violation category name and press Save to add it to the
            system.
          </p>
          <form
            id="create-category-form"
            onSubmit={(values) => {
              setSubmited(true);
              formik.handleSubmit(values);
            }}
          >
            <div className="form__group">
              <span className="formNotification text--bold adds__label">
                Violation Category
              </span>
              <input
                type="text"
                className={`formInput ${
                  formError(
                    formik.errors.violationCategory,
                    formik.values.violationCategory
                  ) && "inputError"
                }`}
                placeholder="Enter a violation category name"
                name="violationCategory"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="formNotification">
                {formError(
                  formik.errors.violationCategory,
                  formik.values.violationCategory
                )
                  ? formik.errors.violationCategory
                  : "Enter violation category name in the field above."}
              </p>
            </div>
          </form>
        </div>
      </EmptyModal>
    </div>
  );
}
