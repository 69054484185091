import React from 'react'
import { Auth } from 'aws-amplify'
import './Modal.scss'

export default function SettingsModal({
  modalIsOpen,
  onCloseModal,
  onLogOut,
  setChangePasswordOpen,
}) {
  async function handleLogout() {
    await Auth.signOut()

    // userHasAuthenticated(false)

    // history.push('/login')
  }
  return (
    <div className={`settingsModal ${modalIsOpen ? 'show' : 'hide'}`}>
      <ul className="modal__list">
        <li
          onClick={setChangePasswordOpen}
          className="modal__list-item link header--four"
        >
          Change Password
        </li>
        <li
          onClick={() => {
            onLogOut()
            handleLogout()
            onCloseModal()
          }}
          className="modal__list-item link header--four"
        >
          Log out
        </li>
        <li
          onClick={onCloseModal}
          className="modal__list-item link header--four"
        >
          Cancel
        </li>
      </ul>
    </div>
  )
}
