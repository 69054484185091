import React from "react";
import "./footer.scss";

export default function Footer() {
  return (
    <div className="footer container">
      <span className="text text--tiny">
        © 2021 Firesprings. All rights reserved
      </span>
    </div>
  );
}
