import React, { useState, useEffect } from "react";
import { commentLimit } from "../../config";
import cutText from "../../utils/cutText";
import { Link } from "react-router-dom";
import routes from "../../routes";

export default function Description({ name, text, userId, stopWords }) {
  const [textIsShorten, setTextIsShorten] = useState(false);

  useEffect(() => {
    text.length > commentLimit && setTextIsShorten(true);
  }, [text]);

  return (
    <div
      className={`comments__block`}
      style={{ paddingLeft: "0", paddingRight: "0" }}
    >
      <div className="comments__top">
        {userId ? (
          <Link
            to={routes.userPreview + "/" + userId}
            className="comments__fullName text text--bold text--small"
          >
            {name}
          </Link>
        ) : (
          <div className="comments__fullName text text--bold text--small">
            {name}
          </div>
        )}
      </div>
      {stopWords ? (
        <div
          className="comments__text"
          dangerouslySetInnerHTML={{
            __html: text
              .split(/(\w+)/g)
              .map((element) => {
                let temp = false;
                for (const elem of stopWords) {
                  temp =
                    elem.name.trim().toLowerCase() ===
                    element.trim().toLowerCase();
                  if (temp) break;
                }
                if (temp)
                  return `<span class="comments__text--warning">${element}</span>`;
                else return element;
              })
              .join(""),
          }}
        ></div>
      ) : (
        // <span className="comments__text">
        //   {textIsShorten ? cutText(text, commentLimit) : text}
        // </span>
        <div
          className="comments__text"
          dangerouslySetInnerHTML={{
            __html: textIsShorten ? cutText(text, commentLimit) : text,
          }}
        ></div>
      )}

      {textIsShorten && !stopWords && (
        <button
          style={{ right: "0px" }}
          onClick={() => setTextIsShorten(false)}
          className="link link--underlined comments__text-button"
        >
          Read more
        </button>
      )}
    </div>
  );
}
