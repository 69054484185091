import React, { useState } from "react";
import "./Table.scss";
import delelteIcon from "../../assets/img/admin/Delete.svg";
import adminCategoryIcon from "../../assets/img/admin/admin-category.svg";
import adminCategoryActiveIcon from "../../assets/img/admin/admin-category-active.svg";
import axios from "axios";
import { api_url } from "../../config";

export default function UserCategoriesTable({ data, handleDeleteModalOpen }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <table className="table usersTable" style={{ maxWidth: "860px" }}>
        <thead>
          <tr className="table__headers">
            <th>Category Name</th>
            <th style={{ textAlign: "center" }}>Created Date (mm/dd/yyyy)</th>
            <th style={{ textAlign: "right" }}>Number of posts</th>
            <th style={{ textAlign: "center" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element) => {
            console.log("asd", element);
            return (
              <tr key={element.id}>
                <td className="text text--bold usersTable__name">
                  {element.name}
                </td>

                <td style={{ textAlign: "center" }}>
                  {new Date(element.created_at).toLocaleDateString("en-us")}
                </td>

                <td
                  className="text text--bold usersTable__name"
                  style={{ textAlign: "right" }}
                >
                  {element.albums_count}
                </td>

                <td className="table__action" style={{ alignItems: "center" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TableAction
                      id={element.id}
                      role={element.role}
                      name={element.name}
                    />
                    <button
                      className="table__action-button"
                      onClick={() => handleDeleteModalOpen(element.id)}
                    >
                      <img src={delelteIcon} alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function TableAction({ role, id, name }) {
  const [categoryRole, setCategoryRole] = useState(role);
  const handleChangeCategory = () => {
    if (categoryRole === "admin") {
      setCategoryRole("user");
      axios
        .post(`${api_url}/admin/categories/edit`, {
          id,
          role: "user",
          name,
        })
        .then((response) => {})
        .catch((err) => console.log(err.response.data));
    } else {
      setCategoryRole("admin");
      axios
        .post(`${api_url}/admin/categories/edit`, {
          id,
          role: "admin",
          name,
        })
        .then((response) => {})
        .catch((err) => console.log(err.response.data));
    }
  };
  return (
    <button className="table__action-button" onClick={handleChangeCategory}>
      <img
        src={
          categoryRole === "user" ? adminCategoryIcon : adminCategoryActiveIcon
        }
        alt="delete"
      />
    </button>
  );
}
