import React, { useState, useEffect } from "react";
import { ReactComponent as EyeIcon } from "../../assets/img/eye.svg";
import { ReactComponent as EyeClosedIcon } from "../../assets/img/eye-closed.svg";
import "./passwordInput.scss";

export default function PasswordInput({ eyeColor, ...props }) {
  const [passwordShow, setPasswordShow] = useState(false);
  const [inputHeight, setInputHeight] = useState("");

  useEffect(() => {
    setInputHeight(document.querySelector(".formInput").scrollHeight);
  }, [inputHeight]);

  const togglePasswordShow = () => {
    passwordShow ? setPasswordShow(false) : setPasswordShow(true);
  };

  return (
    <>
      <input {...props} type={passwordShow ? "text" : "password"} />
      <button
        tabIndex="-1"
        type="button"
        onClick={() => togglePasswordShow()}
        className="password-eye"
        style={{
          height: inputHeight + "px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {passwordShow ? (
          <EyeClosedIcon className={`password-eye--${eyeColor}`} />
        ) : (
          <EyeIcon className={`password-eye--${eyeColor}`} />
        )}
      </button>
      {/* {passwordShow ? (
       
      ) : (
        <button
          style={
            {
              // top: `${inputHeight / 2 - eyeHeight / 2}px`,
            }
          }
          tabIndex="-1"
          type="button"
          onClick={() => setPasswordShow(true)}
          className="password-eye"
        >
          <img src={eyeIcon} alt="eye" />
        </button>
      )} */}
    </>
  );
}

PasswordInput.defaulProps = {
  eyeColor: "grey",
};
