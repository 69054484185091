import React, { useEffect } from 'react'
import Modal from 'react-modal'
import './Modal.scss'
import closeIcon from '../../assets/img/close-modal.svg'
import Avatar from 'react-avatar'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import useInfiniteScroll from '../../utils/useInfiniteScroll'
import ClipLoader from 'react-spinners/ClipLoader'

Modal.setAppElement('#root')

let customStyles = {
  content: {
    top: window.screen.availHeight / 2 + 'px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginLeft: 140 + 'px',
    transform: 'translate(-50%, -50%)',
    paddingLeft: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingTop: '16px',
    minWidth: '450px',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
    overflow: 'hidden',
  },
}
function FollowersModal({
  modalIsOpen,
  onCloseModal,
  followers,
  userId,
  isMyProfile,
  header,
  removeText,
  buttonEvent,
  loadingFollows,
  nextPageUrl,
  loadMoreFollows,
}) {
  useEffect(() => {
    modalIsOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [modalIsOpen])

  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMoreFollows : () => {},
    loadingFollows
  )

  return (
    <Modal
      preventScroll={true}
      isOpen={modalIsOpen}
      onRequestClose={onCloseModal}
      style={customStyles}
    >
      <div className='modal__top followersModal__top'>
        <p className='text text--normal text--bold modal__header'>{header}</p>
        <button onClick={onCloseModal} className='modal__close'>
          <img src={closeIcon} alt='close' />
        </button>
      </div>
      <ul className='followersModal__list'>
        <Scrollbars style={{ width: 'auto', height: 300 }}>
          {followers.map((follower) => {
            let user = follower.follower ? follower.follower : follower.user
            let url = user?.media?.[0]?.url || ''

            return (
              <li className='followersModal__list-item' key={user.id}>
                <Link
                  className='follower'
                  to={routes.userPreview + '/' + user.id}
                >
                  <Avatar
                    size={36}
                    name={user.fullName}
                    round='50%'
                    src={url}
                    maxInitials={2}
                    color='#2E8299'
                  />
                  <div className='follower__info'>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <strong className='follower__info-username text text--small text--bold'>
                        {user.userName}
                      </strong>
                    </div>
                    <span className='follower__info-fullName text text--small'>
                      {user.fullName}
                    </span>
                  </div>
                </Link>
                {user.id !== userId && isMyProfile && (
                  <>
                    {header === 'followers' && (
                      <button className='link text--tiny link--underlined '>
                        Follow
                      </button>
                    )}

                    <button
                      className='followersModal__button button button--outlined'
                      onClick={() => buttonEvent(user.id)}
                    >
                      {removeText}
                    </button>
                  </>
                )}
              </li>
            )
          })}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '5px 0',
            }}
          >
            <ClipLoader color={'#050138'} loading={loadingFollows} size={25} />
          </div>
          <span ref={lastElementRef}></span>
        </Scrollbars>
      </ul>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: authSelectors.getId(state),
  }
}

export default connect(mapStateToProps)(FollowersModal)
