import React, { useState, useEffect } from "react";
import { api_url, commentLimit } from "../../config";
import cutText from "../../utils/cutText";
import PinIcon from "../../assets/img/pin-icon.svg";
import { Link } from "react-router-dom";
import routes from "../../routes";
import deleteIcon from "../../assets/img/delete-icon.svg";
import PinCommentIcon from "../../assets/img/admin/pin-icon.svg";
import favouritresIcon from "../../assets/img/admin/favourites-icon.svg";
import warnIcon from "../../assets/img/admin/warn-icon.svg";
import axios from "axios";
import closeIcon from "../../assets/img/close-modal.svg";
import favouritesActiveIcon from "../../assets/img/admin/favourite-active.svg";
import publishIcon from "../../assets/img/admin/publish.svg";

export default function Comment({
  name,
  text,
  pinned = 0,
  userId,
  id,
  handleOpenModal,
  activeComment,
  handleActiveComment,
  showWarnHint,
  closeWarnHint,
  openWarnHint,
  handleWarnUser,
  favourite,
  stopWords,
  location,
  admin_pinned,
  userStatus,
  handleUserStatus,
  handlePublishComment,
  commentStatus,
}) {
  const [textIsShorten, setTextIsShorten] = useState(false);
  const [adminFavourite, setAdminFavourite] = useState(favourite);
  const [adminPinned, setAdminPinned] = useState(admin_pinned);
  const [status, setStatus] = useState(commentStatus);

  useEffect(() => {
    text.length > commentLimit && setTextIsShorten(true);
  }, [text]);

  const toggleAdminFavourite = () => {
    if (adminFavourite === 1) {
      setAdminFavourite(0);
      axios
        .post(`${api_url}/admin/comments/admin-favorite`, {
          id,
          admin_favorite: 0,
        })
        .then((response) => console.log(response.data));
    } else {
      setAdminFavourite(1);
      axios
        .post(`${api_url}/admin/comments/admin-favorite`, {
          id,
          admin_favorite: 1,
        })
        .then((response) => console.log(response.data));
    }
  };

  const handlePinComment = () => {
    if (!adminPinned) {
      setAdminPinned(1);
      axios
        .post(`${api_url}/admin/comments/admin-pinned`, {
          id,
          admin_pinned: 1,
        })
        .then((response) => console.log(response.data));
    } else {
      setAdminPinned(0);
      axios
        .post(`${api_url}/admin/comments/admin-pinned`, {
          id,
          admin_pinned: 0,
        })
        .then((response) => console.log(response.data));
    }
  };

  return (
    <div
      className={`comments__block ${
        activeComment && "comments__block--active"
      } ${pinned && "pinned"}`}
      onClick={() => handleActiveComment(id)}
    >
      {activeComment && (
        <div className="post__actions" style={{ left: "119%", bottom: "0" }}>
          {(location.pathname === routes.posts + "/feedView" ||
            location.pathname === routes.posts + "/favourites") && (
            <div className="post__actions-block">
              <button
                className="post__actions-button"
                onClick={toggleAdminFavourite}
              >
                <img
                  src={adminFavourite ? favouritesActiveIcon : favouritresIcon}
                  alt="favourites"
                />
              </button>
              <span className="post__actions-text text--bold link text--tiny">
                {adminFavourite ? "remove from favorite" : "add to favorite"}
              </span>
            </div>
          )}

          {(location.pathname === routes.posts + "/feedView" ||
            location.pathname === routes.posts + "/needReview") && (
            <div className="post__actions-block">
              <button className="post__actions-button" onClick={openWarnHint}>
                <img src={warnIcon} alt="warn" />
              </button>
              <span className="post__actions-text text--bold link text--tiny">
                warn/mute/ban
              </span>
              {showWarnHint && (
                <div className="post__actions-hint">
                  <button
                    className="post__actions-hintClose"
                    onClick={closeWarnHint}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                  {userStatus === 1 && (
                    <span
                      className="post__actions-hintElement text text--normal"
                      onClick={() => handleUserStatus(3)}
                    >
                      Warn
                    </span>
                  )}
                  {userStatus === 3 && (
                    <span
                      className="post__actions-hintElement text text--normal"
                      onClick={() => handleUserStatus(4)}
                    >
                      Mute
                    </span>
                  )}
                  {userStatus === 4 && (
                    <span
                      className="post__actions-hintElement text text--normal"
                      onClick={() => handleUserStatus(2)}
                    >
                      Ban
                    </span>
                  )}
                  {userStatus === 2 && (
                    <span
                      className="post__actions-hintElement text text--normal"
                      onClick={() => handleUserStatus(1)}
                    >
                      Activate
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {location.pathname === routes.posts + "/favourites" && (
            <div className="post__actions-block">
              <button
                className="post__actions-button"
                onClick={handlePinComment}
              >
                <img src={PinCommentIcon} alt="pin" />
              </button>
              <span className="post__actions-text text--bold link text--tiny">
                {adminPinned ? "unpin" : "pin"} comment
              </span>
            </div>
          )}

          {location.pathname === routes.posts + "/needReview" && status !== 1 && (
            <div className="post__actions-block">
              <button
                className="post__actions-button"
                onClick={() => {
                  handlePublishComment(id);
                  setStatus(1);
                }}
              >
                <img src={publishIcon} alt="publish" />
              </button>
              <span className="post__actions-text text--bold link text--tiny">
                publish comment
              </span>
            </div>
          )}

          <div className="post__actions-block">
            <button
              className="post__actions-button"
              onClick={() => handleOpenModal(id)}
            >
              <img src={deleteIcon} alt="delete" />
            </button>
            <span className="post__actions-text text--bold link text--tiny">
              delete
            </span>
          </div>
        </div>
      )}
      <div className="comments__top">
        <Link
          to={routes.userPreview + "/" + userId}
          className="comments__fullName text text--bold text--small"
        >
          {name}
        </Link>
        {adminPinned ? (
          <span
            className="text text--bold text--small"
            style={{ zIndex: "50" }}
          >
            Editor's choice
          </span>
        ) : (
          ""
        )}
      </div>

      {stopWords && status === 3 ? (
        <div
          className="comments__text"
          dangerouslySetInnerHTML={{
            __html: text
              .split(/(\w+)/g)
              .map((element) => {
                let temp = false;
                for (const elem of stopWords) {
                  temp =
                    elem.name.trim().toLowerCase() ===
                    element.trim().toLowerCase();
                  if (temp) break;
                }
                if (temp)
                  return `<span class="comments__text--warning">${element}</span>`;
                else return element;
              })
              .join(""),
          }}
        ></div>
      ) : (
        <span className="comments__text">
          {textIsShorten ? cutText(text, commentLimit) : text}
        </span>
      )}

      {textIsShorten && (
        <button
          onClick={() => setTextIsShorten(false)}
          className="link link--underlined comments__text-button"
        >
          Read more
        </button>
      )}
      {pinned && (
        <div className="comments__pinIcon">
          <img src={PinIcon} alt="pin" />
        </div>
      )}
    </div>
  );
}
