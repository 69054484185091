import * as Yup from "yup";

import values from "./schemaValues";

const { title, fullName } = values;

const categoryTitleSchema = Yup.object({
  title,
  fullName,
});

export default categoryTitleSchema;
