import React, { useEffect, useState } from 'react'
import { PostsList, Navigation, ModalWithButtons } from '../../components'
import ClipLoader from 'react-spinners/ClipLoader'
import routes from '../../routes'
import useInfiniteScroll from '../../utils/useInfiniteScroll'
import axios from 'axios'
import { api_url } from '../../config'
import { useLocation } from 'react-router-dom'
import refreshIcon from '../../assets/img/admin/refresh-icon.svg'

export default function NeedReview({
  handleActivePost,
  activeComment,
  handleActiveComment,
  activePost,
  userId,
}) {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [postsPage, setPostsPage] = useState(1)
  const [stopWords, setStopWords] = useState([])
  const [deletePostModalIsOpen, setDeletePostModalIsOpen] = useState(false)
  const [deletePostId, setDeletePostId] = useState(null)
  const [deletedPosts, setDeletedPosts] = useState([])
  const [shouldUpdate, setShouldUpdate] = useState(1)

  const location = useLocation()

  useEffect(() => {
    setPosts([])
    setLoading(false)
    setNextPageUrl(null)
    setPostsPage(1)
  }, [location])

  const loadMorePosts = () => {
    if (nextPageUrl && !loading) {
      setPostsPage((prevState) => prevState + 1)
    }
  }

  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMorePosts : () => {},
    loading
  )

  const handlePublishPost = (id) => {
    axios
      .post(`${api_url}/admin/albums/change-status`, {
        id,
        status: 1,
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err))
    setPosts((prevState) => prevState.filter((post) => post.id !== id))
  }

  const handleRevertDeletions = () => {
    setDeletedPosts([])
    deletedPosts.forEach((id) => {
      axios
        .post(`${api_url}/admin/albums/deleted`, {
          id,
          is_deleted: 0,
        })
        .then((response) => handleGetNewPosts())
    })
  }

  const handleDeletePostModalOpen = (id) => {
    setDeletePostId(id)
    setDeletePostModalIsOpen(true)
  }

  const handleGetNewPosts = () => {
    setPosts([])
    setPostsPage(1)
    setShouldUpdate((prevState) => prevState + 1)
  }

  const handleDeletePost = () => {
    setDeletedPosts((prevState) => [...prevState, deletePostId])
    setPosts((prevState) =>
      prevState.filter((post) => post.id !== deletePostId)
    )

    setDeletePostModalIsOpen(false)
    axios
      .post(`${api_url}/admin/albums/deleted`, {
        id: deletePostId,
        is_deleted: 1,
      })
      .then((response) => console.log('dekete', response.data))
  }

  const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    if (token) {
      setLoading(true)
      axios
        .post(`${api_url}/admin/albums/get/by-status?page=${postsPage}`, {
          status: 3,
        })
        .then((response) => {
          if (posts && nextPageUrl) {
            setPosts((prevState) => [
              ...prevState,
              ...response.data.albums.data,
            ])
          } else {
            setPosts(response.data.albums.data)
          }
          setNextPageUrl(response.data.albums.next_page_url)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))

      axios
        .get(`${api_url}/stop-words`)
        .then(
          (response) => response.data.words && setStopWords(response.data.words)
        )
        .catch((err) => console.log(err))
    }

    // eslint-disable-next-line
  }, [postsPage, token, shouldUpdate, location])

  return (
    <>
      {!loading && (
        <a href='#root' className='refresh-button'>
          <img src={refreshIcon} alt='refresh' />
        </a>
      )}
      {deletedPosts.length > 0 && (
        <button className='link revert-button' onClick={handleRevertDeletions}>
          Restore deleted post(s)
        </button>
      )}
      <Navigation
        prevPage={`Post ${'&'} Comments`}
        currentPage='Need Review'
        prevRoute={routes.posts + '/needReview'}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: '50px',
        }}
      >
        {posts && posts.length > 0 && (
          <>
            <h4
              style={{
                marginBottom: '19px',
                width: '723px',
                textAlign: 'center',
              }}
              className='header header--four'
            >
              Feed public view
            </h4>
            <PostsList
              posts={posts}
              userId={userId}
              handleActivePost={handleActivePost}
              activePost={activePost}
              activeComment={activeComment}
              handleActiveComment={handleActiveComment}
              stopWords={stopWords}
              handleDeletePostModalOpen={handleDeletePostModalOpen}
              handlePublishPost={handlePublishPost}
            />
          </>
        )}
        <span className='profile__lastElement' ref={lastElementRef}></span>
      </div>

      <div
        style={{
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {posts && posts.length > 0 && (
          <h4 className='header header--four'>Admin actions</h4>
        )}
      </div>
      {loading && (
        <div
          style={{
            width: '1000px',
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ClipLoader color={'#050138'} loading={loading} size={75} />
        </div>
      )}
      <ModalWithButtons
        modalIsOpen={deletePostModalIsOpen}
        onCloseModal={() => setDeletePostModalIsOpen(false)}
        header='Delete Post'
        text='Are you sure you want to delete a post? This action cannot be reverted.'
        onConfirm={handleDeletePost}
      />
    </>
  )
}
