const checkQuantity = (value, measurment) => {
  let val = value;
  if (val % 10 === 1 && val !== 11) {
    val += ` ${measurment}`;
  } else {
    val += ` ${measurment}s`;
  }
  return val;
};

export default checkQuantity;
