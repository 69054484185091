import React, { useEffect, useState } from 'react'
import { PostsList, Navigation } from '../../components'
import ClipLoader from 'react-spinners/ClipLoader'
import routes from '../../routes'
import useInfiniteScroll from '../../utils/useInfiniteScroll'
import axios from 'axios'
import { api_url } from '../../config'
import { useLocation } from 'react-router-dom'
import refreshIcon from '../../assets/img/admin/refresh-icon.svg'

export default function Favourites({
  handleActivePost,
  activeComment,
  handleActiveComment,
  activePost,
  userId,
}) {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [postsPage, setPostsPage] = useState(1)

  const loadMorePosts = () => {
    if (nextPageUrl && !loading) {
      setPostsPage((prevState) => prevState + 1)
    }
  }

  const location = useLocation()

  useEffect(() => {
    setPosts([])
    setLoading(false)
    setNextPageUrl(null)
    setPostsPage(1)
  }, [location])

  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMorePosts : () => {},
    loading
  )

  const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    if (token) {
      setLoading(true)
      axios
        .get(`${api_url}/admin/albums/get/admin-favorite?page=${postsPage}`)
        .then((response) => {
          setLoading(true)
          if (posts && nextPageUrl) {
            setPosts((prevState) => [
              ...prevState,
              ...response.data.albums.data,
            ])
          } else {
            setPosts(response.data.albums.data)
          }
          setLoading(false)
          setNextPageUrl(response.data.albums.next_page_url)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }

    // eslint-disable-next-line
  }, [postsPage, token, location])

  return (
    <>
      {!loading && (
        <a href='#root' className='refresh-button'>
          <img src={refreshIcon} alt='refresh' />
        </a>
      )}
      <Navigation
        prevPage={`Post ${'&'} Comments`}
        currentPage='Favorites'
        prevRoute={routes.posts + '/favourites'}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: '50px',
        }}
      >
        {posts && posts.length > 0 && (
          <>
            <h4
              style={{
                marginBottom: '19px',
                width: '723px',
                textAlign: 'center',
              }}
              className='header header--four'
            >
              Feed public view
            </h4>
            <PostsList
              posts={posts}
              userId={userId}
              handleActivePost={handleActivePost}
              activePost={activePost}
              activeComment={activeComment}
              handleActiveComment={handleActiveComment}
            />
          </>
        )}
        <span className='profile__lastElement' ref={lastElementRef}></span>
      </div>

      <div
        style={{
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {posts && posts.length > 0 && (
          <h4 className='header header--four'>Admin actions</h4>
        )}
      </div>
      {loading && (
        <div
          style={{
            width: '1000px',
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ClipLoader color={'#050138'} loading={loading} size={75} />
        </div>
      )}
    </>
  )
}
