import React, { useEffect, useState } from 'react'
import { Navigation } from '../../components'
import ClipLoader from 'react-spinners/ClipLoader'
import routes from '../../routes'
import axios from 'axios'
import { api_url } from '../../config'
import refreshIcon from '../../assets/img/admin/refresh-icon.svg'
import Avatar from 'react-avatar'

import stopStreaming from '../../assets/img/StopStreaming.png'
import './stream.scss'

const StreamBlock = ({ id, fullName, userName, avatar, isUpdate }) => {
  const [isTooltip, setIsTooltip] = useState(false)

  const stopStream = () => {
    axios
      .post(`${api_url}/admin/live/stop`, { id })
      .catch((err) => console.log(err))
      .finally(isUpdate)
  }

  return (
    <div className="steamer__block">
      <div className="steamer__block-left">
        <Avatar
          size={50}
          name={fullName}
          round="50%"
          src={avatar}
          maxInitials={2}
          color="#2E8299"
        />
        <div className="steamer__block-text">
          <span className="steamer__block-nickName">{userName}</span>
          <span className="steamer__block-fullName">{fullName}</span>
        </div>
      </div>
      <button className="steamer__block-button" onClick={stopStream}>
        <img
          src={stopStreaming}
          alt=""
          onMouseMove={() => setIsTooltip(true)}
          onMouseLeave={() => setIsTooltip(false)}
        />
        <div
          style={{ display: isTooltip ? 'block' : 'none' }}
          className="steamer__block-buttonToolTip"
        >
          Stop streaming
        </div>
      </button>
    </div>
  )
}

export default function Stream() {
  const [streams, setStreams] = useState([])
  const [loading, setLoading] = useState(true)
  const token = axios.defaults.headers.common.Authorization

  const getStreamsList = () => {
    setLoading(true)
    axios
      .get(`${api_url}/admin/live/active`)
      .then((response) => setStreams(response?.data?.stream || []))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (token) {
      getStreamsList()
    }
    // eslint-disable-next-line
  }, [token])

  return (
    <>
      {!loading && (
        <a href="#root" className="refresh-button">
          <img src={refreshIcon} alt="refresh" onClick={getStreamsList} />
        </a>
      )}
      <Navigation
        prevPage={`Post ${'&'} Comments`}
        currentPage="Live stream"
        prevRoute={routes.posts + '/live-stream'}
      />
      {loading ? (
        <div
          style={{
            width: '1000px',
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ClipLoader color={'#050138'} loading={loading} size={75} />
        </div>
      ) : (
        <div className="steamer">
          {streams.map((stream) => (
            <StreamBlock
              id={stream?.id ?? ''}
              fullName={stream?.user_name?.fullName ?? ''}
              userName={stream?.user_name?.userName ?? ''}
              avatar={stream?.user_name?.media?.[0]?.url ?? ''}
              isUpdate={getStreamsList}
            />
          ))}
        </div>
      )}
    </>
  )
}
