import React from "react";
import "./Table.scss";
import delelteIcon from "../../assets/img/admin/Delete.svg";
import adminCategoryActiveIcon from "../../assets/img/admin/admin-category-active.svg";

export default function AdminCategoriesTable({ data, handleDeleteModalOpen }) {
  return (
    <div
      className="categoriesTable"
      style={{ width: "683px", padding: "60px 70px" }}
    >
      <table className="table">
        <thead>
          <tr className="table__headers">
            <th>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Category Name</span>{" "}
                <img src={adminCategoryActiveIcon} alt="category" />
              </div>
            </th>
            <th style={{ textAlign: "right" }}>Number of posts</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element) => {
            return (
              <tr key={element.id}>
                <td className="text text--bold" style={{ textAlign: "left" }}>
                  {element.name}
                </td>

                <td className="text text--bold" style={{ textAlign: "right" }}>
                  {element.albums_count}
                </td>

                <td className="table__action">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="table__action-button"
                      onClick={() => handleDeleteModalOpen(element.id)}
                    >
                      <img src={delelteIcon} alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
