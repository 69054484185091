import React, { useEffect } from "react";
import "./index.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  LogIn,
  NotFound,
  Users,
  CreateAdd,
  Adds,
  EditAdd,
  StopList,
  ViolationList,
  UserPreview,
  Posts,
  Categories,
  ChooseCategory,
  ChooseFile,
  ChooseText,
  ChooseFilter,
  Background,
} from "./views";
import { Header, Footer, PublicRoute, PrivateRoute } from "./components";
import routes from "./routes";
import authOperations from "./redux/auth/authOperations";
import { connect } from "react-redux";
import authSelectors from "./redux/auth/authSelectors";
import AsideNavigation from "./components/AsideNavigation/AsideNavigation";
import postOperations from "./redux/post/postOperations";

function Main({ getUser, rememberMe, logOut, getAvatar, id,getBackgroundsList }) {
  useEffect(() => {
    document.getElementById("root").style.minHeight =
      window.screen.height + "px";
  });

  useEffect(() => {
    getUser();
    getBackgroundsList()
  }, [getUser, getBackgroundsList]);

  useEffect(() => {
    if (id) {
      getAvatar();
    }
  }, [getAvatar, id, getBackgroundsList]);

  useEffect(() => {
    console.log("rememberMe", rememberMe);
    window.onbeforeunload = function () {
      if (rememberMe === false) {
        logOut();
        Storage.clear();
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [rememberMe, logOut]);

  return (
    <>
      <Header />
      <AsideNavigation />

      <Switch>
        <PublicRoute
          exact
          path={routes.logIn}
          component={LogIn}
          redirectRoute={routes.home}
        />

        <PrivateRoute
          exact
          path={routes.users}
          component={Users}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.createAdd}
          component={CreateAdd}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.adds}
          component={Adds}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.stopList}
          component={StopList}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.editAdd + "/:id"}
          component={EditAdd}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.violationList}
          component={ViolationList}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.userPreview + "/:id"}
          component={UserPreview}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          path={routes.posts}
          component={Posts}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          path={routes.categories}
          component={Categories}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          path={routes.createPost}
          component={ChooseCategory}
          redirectRoute={routes.logIn}
        />
        <PrivateRoute
          path={routes.chooseFile}
          component={ChooseFile}
          redirectRoute={routes.logIn}
        />
        <PrivateRoute
          path={routes.chooseFilter}
          component={ChooseFilter}
          redirectRoute={routes.chooseFilter}
        />
        <PrivateRoute
          path={routes.chooseText}
          component={ChooseText}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
            exact
            path={routes.backgroundEdit}
            component={Background}
            redirectRoute={routes.logIn}
        />

        <Route exact path="/">
          <Redirect to={routes.users} />
        </Route>
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    rememberMe: authSelectors.rememberMe(state),
    id: authSelectors.getId(state),
  };
};

export default connect(mapStateToProps, {
  getUser: authOperations.getCurrentUser,
  getAvatar: authOperations.getUserAvatar,
  logOut: authOperations.logOut,
  getBackgroundsList: postOperations.getBackgroundsList,
})(Main);
