import * as Yup from "yup";

import values from "./schemaValues";

const { othersInput } = values;

const createCategory = Yup.object({
  othersInput,
});

export default createCategory;
