import React from "react";
import { Comment } from "../../components";
import { useLocation } from "react-router-dom";

export default function CommentsList({
  comments,
  currentUserId,
  handleOpenModal,
  isMyProfile,
  togglePinComment,
  activeComment,
  handleActiveComment,
  showWarnHint,
  closeWarnHint,
  handleWarnUser,
  openWarnHint,
  stopWords,
  userStatus,
  handleUserStatus,
  handlePublishComment,
}) {
  const location = useLocation();
  return (
    <>
      {comments.map((comment) => {
        let isMyComment = comment.user.id === currentUserId;
        let pinned = comment.pinned === 1;
        console.log("comment", comment);
        return (
          <Comment
            activeComment={comment.id === activeComment}
            handleActiveComment={handleActiveComment}
            name={comment.user.fullName}
            text={comment.text}
            key={comment.id}
            pinned={pinned}
            userId={comment.user.id}
            isMyProfile={isMyProfile}
            isMyComment={isMyComment}
            id={comment.id}
            handleOpenModal={handleOpenModal}
            togglePinComment={togglePinComment}
            showWarnHint={showWarnHint}
            closeWarnHint={closeWarnHint}
            handleWarnUser={handleWarnUser}
            openWarnHint={openWarnHint}
            favourite={comment.admin_favorite}
            stopWords={stopWords}
            location={location}
            admin_pinned={comment.admin_pinned}
            userStatus={userStatus}
            handleUserStatus={handleUserStatus}
            handlePublishComment={handlePublishComment}
            commentStatus={comment.status}
          />
        );
      })}
    </>
  );
}
