import React, { useState, useEffect } from 'react'
import searchIcon from '../../assets/img/search-icon.svg'
import UsersTable from '../../components/Table/UsersTable'
import routes from '../../routes'
import { Navigation, ModalWithButtons } from '../../components'
import axios from 'axios'
import { api_url } from '../../config'
import ClipLoader from 'react-spinners/ClipLoader'
import './Users.scss'

export default function Users() {
  const [searchQuery, setSearchQuery] = useState('')
  const [users, setUsers] = useState(null)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(null)
  const [loading, setLoading] = useState(true)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const token = axios.defaults.headers.common.Authorization

  const handleDeleteModalOpen = (id = null) => {
    setDeleteId(id)
    setDeleteModalIsOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteId(null)
    setDeleteModalIsOpen(false)
  }

  const handleDeleteUser = () => {
    axios.delete(`${api_url}/admin/users/remove/${deleteId}`).then(() => {
      setUsers((prevState) => prevState.filter((el) => el.id !== deleteId))
      getUsers()
    })
    handleDeleteModalClose()
  }

  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1)
    }
  }

  const getUsers = () => {
    setLoading(true)
    axios
      .get(`${api_url}/admin/users?page=${page}`)
      .then((response) => {
        console.log('users', response.data)
        setLoading(false)
        setUsers(response.data.users.data)
        setNextPageUrl(response.data.users.next_page_url)
        setTotal(response.data.users.total)
      })
      .catch((err) => console.log(err.response.data))
  }

  useEffect(() => {
    if (token && !searchQuery) {
      getUsers()
    }
    // eslint-disable-next-line
  }, [token, page, searchQuery])

  useEffect(() => {
    if (searchQuery && !loading) {
      setLoading(true)
      axios
        .post(`${api_url}/admin/users/search?page=${page}`, {
          name: searchQuery,
        })
        .then((response) => {
          console.log('users', response.data)
          setLoading(false)
          setUsers(response.data.users.data)
          setNextPageUrl(response.data.users.next_page_url)
          setTotal(response.data.users.total)
        })
        .catch((err) => console.log(err.response.data))
    }
    // eslint-disable-next-line
  }, [searchQuery, page])

  useEffect(() => {
    setPage(1)
  }, [searchQuery])

  return (
    <div className="container users">
      <div className="wrap users__wrap">
        <Navigation
          prevPage="Users"
          currentPage="Users"
          prevRoute={routes.users}
        />
        <div className="searchPannel">
          <form
            className="searchbar users__searchbar"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="searchbar__button">
              <img src={searchIcon} alt="search" />
            </div>
            <input
              type="text"
              className="searchbar__input formInput"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.trimStart())}
              placeholder="Search"
            />
          </form>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ClipLoader color={'#050138'} loading={loading} size={75} />
        </div>

        {users && !loading && users.length > 0 ? (
          <UsersTable
            data={users}
            page={page}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        ) : (
          !loading && (
            <h3
              className="header--three text--center"
              style={{ marginTop: '50px' }}
            >
              No users found
            </h3>
          )
        )}
      </div>
      <div className="adds__pagination">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {page > 1 ? 20 * (page - 1) + 1 : 1}-{20 * page} of {total} Items
          <button
            type="button"
            className={`adds__pagination-button ${page < 2 && 'disabled'}`}
            onClick={handlePrevPage}
          >
            {'<'}
          </button>
          <button
            type="button"
            className={`adds__pagination-button ${!nextPageUrl && 'disabled'}`}
            onClick={handleNextPage}
          >
            {'>'}
          </button>
        </div>
      </div>
      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete user"
        text="Are you sure you want to delete an user? This action cannot be reverted."
        onConfirm={handleDeleteUser}
      />
    </div>
  )
}
