import React, { useEffect, useState } from "react";
import StopTable from "../../components/Table/StopTable";
import axios from "axios";
import {
  ModalWithButtons,
  Navigation,
  Button,
  EmptyModal,
  ModalWindow,
} from "../../components";
import { api_url } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import routes from "../../routes";
import { useFormik } from "formik";
import { Scrollbars } from "react-custom-scrollbars-2";
import expandIcon from "../../assets/img/admin/expand-icon.svg";
import closeIcon from "../../assets/img/close-modal.svg";
import createStopWordSchema from "../../validationSchemas/createStopWordSchema";
// import cutText from "../../utils/cutText";

export default function StopList() {
  const [page, setPage] = useState(1);
  const [words, setWords] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showHint, setShowHint] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "",
  });
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const token = axios.defaults.headers.common.Authorization;

  useEffect(() => {
    setLoading(true);
    if (token) {
      axios
        .get(`${api_url}/admin/stop-words?page=${page}`)
        .then((response) => {
          console.log(response.data);
          setWords(response.data.words.data);
          setNextPageUrl(response.data.words.next_page_url);
          setTotal(response.data.words.total);
          setLoading(false);
        })
        .catch((err) => console.log(err));

      axios
        .get(`${api_url}/admin/violation-categories`)
        .then((response) => {
          console.log(response.data);
          setCategories(response.data.categories);
        })
        .catch((err) => console.log(err));
    }
  }, [token, page, submited]);

  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage((prevState) => prevState + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1);
    }
  };

  const handleDeleteModalOpen = (id = null) => {
    if (checked.length > 0 || id) {
      setDeleteId(id);
      setDeleteModalIsOpen(true);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalIsOpen(false);
  };

  const handleDeleteAd = () => {
    setDeleteModalIsOpen(false);
    if (checked && checked.length > 0 && !deleteId) {
      checked.forEach((id) => {
        axios
          .delete(`${api_url}/admin/stop-words/remove/${id}`)
          .then(() => {
            setDeleteId(null);
            setChecked([]);
          })
          .catch((err) => console.log(err));
        setWords((prevState) => prevState.filter((el) => el.id !== id));
      });
    } else {
      axios
        .delete(`${api_url}/admin/stop-words/remove/${deleteId}`)
        .then(() => {
          setDeleteId(null);
          setChecked([]);
        })
        .catch((err) => console.log(err));
      setWords((prevState) => prevState.filter((el) => el.id !== deleteId));
    }
  };

  const handleCheckboxStatusChange = (e, id) => {
    console.log("e", e);
    if (e) {
      setChecked((prevState) => [...prevState, id]);
    } else {
      setChecked((prevState) => [...prevState.filter((el) => el !== id)]);
    }
  };

  const handleSelect = (name, id) => {
    setSelectedCategory({ name, id });
    setShowHint(false);
  };

  const formik = useFormik({
    initialValues: {
      stopWord: "",
    },
    validationSchema: createStopWordSchema,
    onSubmit: (values) => {
      if (selectedCategory.name) {
        setModalIsOpen(false);
        setSubmited(false);
        const formdata = new FormData();
        formdata.append("name", values.stopWord);
        formdata.append("category_id", selectedCategory.id);
        axios
          .post(`${api_url}/admin/stop-words/create`, formdata)
          .then((response) => {
            setSuccessModalIsOpen(true);
            console.log(response.data);
          })
          .catch((err) => console.log(err.response.data));
      }
    },
  });

  const handleCloseModal = () => {
    setSelectedCategory({ name: "", id: "" });
    formik.resetForm();
    setModalIsOpen(false);
    setSubmited(false);
  };

  const formError = (error, value) => {
    if ((error && value) || (error && submited)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="container">
      <div className="wrap adds__wrap">
        <Navigation
          prevPage="Stop List"
          currentPage={"Stop Words"}
          prevRoute={routes.stopList}
        />

        <Button
          text="Add Stop Word"
          className="button button--primary stopList__button"
          onClick={() => setModalIsOpen(true)}
        />
        <button
          type="button"
          onClick={() => handleDeleteModalOpen()}
          className="link violationList__delete"
        >
          Delete selected
        </button>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={"#050138"} loading={loading} size={75} />
        </div>
        {words && !loading && words.length > 0 ? (
          <StopTable
            handleCheckboxStatusChange={handleCheckboxStatusChange}
            data={words}
            handleDeleteModalOpen={handleDeleteModalOpen}
            checked={checked}
            clearSelected={() => setChecked([])}
            page={page}
          />
        ) : (
          !loading && (
            <div className="header header--three text--center">
              No words added
            </div>
          )
        )}
        <div className="adds__pagination">
          <div style={{ display: "flex", alignItems: "center" }}>
            {page > 1 ? 20 * (page - 1) + 1 : 1}-{20 * page} of {total} Items
            <button
              type="button"
              className={`adds__pagination-button ${page < 2 && "disabled"}`}
              onClick={handlePrevPage}
            >
              {"<"}
            </button>
            <button
              type="button"
              className={`adds__pagination-button ${
                !nextPageUrl && "disabled"
              }`}
              onClick={handleNextPage}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>
      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete a stop word"
        text="Are you sure you want to delete a stop word(s)? This action cannot be reverted."
        onConfirm={handleDeleteAd}
      />
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={successModalIsOpen}
        onCloseModal={() => setSuccessModalIsOpen(false)}
        header="Stop word added"
        text={`Stop word <b>[${formik.values.stopWord}]</b> has been added to stop words list.`}
      />
      <EmptyModal
        modalIsOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        header="Add stop word"
        buttonFor="add-stopWord-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className="text text--center">
            Define a violation category, enter the exact stop word/word
            combination and press Save to add the words to the Stop List
          </p>
          <form
            id="add-stopWord-form"
            onSubmit={(values) => {
              setSubmited(true);
              formik.handleSubmit(values);
            }}
            style={{ width: "530px", margin: "0 auto" }}
          >
            <div className="form__group">
              <span className="formNotification text--bold adds__label">
                Violation Category
              </span>
              <div
                className={`formInput ${showHint && "formInput--focused"} ${
                  submited && !selectedCategory.name && "inputError"
                }`}
                onClick={(e) => {
                  if (!e.target.classList.contains("avoidClick")) {
                    console.log(e.target);
                    showHint ? setShowHint(false) : setShowHint(true);
                  }
                  // e.stopPropagation();
                }}
              >
                <span
                  style={{
                    display: "block",
                    color: "#050138",
                    opacity: "0.6",
                    // maxWidth: "280px",
                    overflow: "hidden",
                  }}
                >
                  {selectedCategory.name
                    ? selectedCategory.name
                    : "select a violation category"}
                </span>

                {selectedCategory.name && (
                  <button
                    type="button"
                    className="stopList__cancel avoidClick"
                    onClick={() => {
                      setSelectedCategory({ name: "", id: "" });
                      setShowHint(false);
                    }}
                  >
                    <img className="avoidClick" src={closeIcon} alt="close" />
                  </button>
                )}
                <button
                  type="button"
                  className={`stopList__expand ${
                    showHint && "stopList__expand--active"
                  }`}
                >
                  <img src={expandIcon} alt="expand" />
                </button>

                <div className={`hint ${showHint && "show"}`}>
                  <Scrollbars style={{ width: "auto", height: 450 }}>
                    {categories.map((category, i) => {
                      return (
                        <div
                          style={{ paddingRight: "30px" }}
                          className={`hint__category`}
                          key={category.name}
                          onClick={() => {
                            handleSelect(category.name, category.id);
                          }}
                        >
                          <div
                            className="hint__category-info text text--normal"
                            style={{ overflow: "hidden", display: "block" }}
                          >
                            <span className="hint__category-name">
                              {category.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Scrollbars>
                </div>
              </div>
              <p className="formNotification">
                Select a violation category using the drop-down menu.
              </p>
            </div>

            <div className="form__group">
              <span className="formNotification text--bold adds__label">
                Stop Word
              </span>
              <input
                type="text"
                className={`formInput ${
                  formError(formik.errors.stopWord, formik.values.stopWord) &&
                  "inputError"
                }`}
                placeholder="enter stop word"
                name="stopWord"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ paddingRight: "30px" }}
                value={formik.values.stopWord}
              />
              {formik.values.stopWord && (
                <button
                  style={{ right: "15px" }}
                  type="button"
                  className="stopList__cancel avoidClick"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  <img className="avoidClick" src={closeIcon} alt="close" />
                </button>
              )}
              <p className="formNotification">
                {formError(formik.errors.stopWord, formik.values.stopWord)
                  ? formik.errors.stopWord
                  : " Enter the stop word or word combination in the field."}
              </p>
            </div>
          </form>
        </div>
      </EmptyModal>
    </div>
  );
}
