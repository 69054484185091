import React, { useState, useEffect } from 'react'
import { Button, ModalWithButtons, Navigation } from '../../components'
import { useHistory } from 'react-router-dom'
import routes from '../../routes'
import AddsTable from '../../components/Table/AddsTable'
import axios from 'axios'
import { api_url } from '../../config'
import ClipLoader from 'react-spinners/ClipLoader'

export default function Adds() {
  const [adds, setAdds] = useState(null)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const history = useHistory()
  const token = axios.defaults.headers.common.Authorization

  const handleDeleteModalOpen = (id) => {
    setDeleteId(id)
    setDeleteModalIsOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModalIsOpen(false)
  }

  const handleDeleteAd = () => {
    setDeleteModalIsOpen(false)
    axios
      .delete(`${api_url}/admin/advertisements/remove/${deleteId}`)
      .then((response) => console.log(response))
      .catch((err) => console.log(err))
    setAdds((prevState) => prevState.filter((add) => add.id !== deleteId))
  }

  useEffect(() => {
    setLoading(true)
    setAdds(null)
    axios
      .get(`${api_url}/admin/advertisements?page=${page}`)
      .then((response) => {
        setLoading(false)
        console.log(response.data)
        setAdds(response.data.advertisements.data)
        setNextPageUrl(response.data.advertisements.next_page_url)
        setTotal(response.data.advertisements.total)
      })
      .catch((err) => console.log('ads err ==>', err))
  }, [token, page])

  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className="container">
      <div className="wrap adds__wrap">
        <Navigation prevPage="Ads" currentPage="Ads" prevRoute={routes.adds} />
        <Button
          text="Create new Ads"
          className="button button--primary createAdd__create"
          onClick={() => history.push(routes.createAdd)}
        />

        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ClipLoader color={'#050138'} loading={loading} size={75} />
        </div>

        {adds && adds.length > 0 ? (
          <AddsTable
            data={adds}
            handleDeleteModalOpen={handleDeleteModalOpen}
            page={page}
          />
        ) : (
          !loading && (
            <h3 className="header--three text--center">No ads added</h3>
          )
        )}
        <div className="adds__pagination">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {page > 1 ? 20 * (page - 1) + 1 : 1}-{20 * page} of {total} Items
            <button
              type="button"
              className={`adds__pagination-button ${page < 2 && 'disabled'}`}
              onClick={handlePrevPage}
            >
              {'<'}
            </button>
            <button
              type="button"
              className={`adds__pagination-button ${
                !nextPageUrl && 'disabled'
              }`}
              onClick={handleNextPage}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete Ad"
        text="Are you sure you want to delete an ad? This action cannot be reverted."
        onConfirm={handleDeleteAd}
      />
    </div>
  )
}
