import { createAction } from "@reduxjs/toolkit";

const addFile = createAction("post/addFile");
const addText = createAction("post/addText");
const addCategory = createAction("post/addCategory");
const addTitle = createAction("post/addTitle");
const addFullName = createAction("post/addFullName");
const addStyle = createAction("post/addStyle");
const addDefaultBackground = createAction("post/addDefaultBackground");
const clearState = createAction("post/clearState");
const getStopWords = createAction("post/getStopWords");
const backgroundsList = createAction("post/backgroundsList");
const clearBackground= createAction("post/clearTitle");

const postActions = {
  addFile,
  addText,
  addCategory,
  addStyle,
  clearState,
  getStopWords,
  addTitle,
  addFullName,
  backgroundsList,
  clearBackground,
  addDefaultBackground
};

export default postActions;
