import * as Yup from "yup";

import values from "./schemaValues";

const { violationCategory } = values;

const createViolationCategorySchema = Yup.object({
  violationCategory,
});

export default createViolationCategorySchema;
