import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SelectFile } from '../../components'
// import plusIcon from "../../assets/img/plus.svg";
import { connect } from 'react-redux'
import postSelectors from '../../redux/post/postSelectors'
import routes from '../../routes'
import postOperations from '../../redux/post/postOperations'
import CreatePost from './CreatePost'
import categoryTitleSchema from '../../validationSchemas/categoryTitleSchema'
import { useFormik } from 'formik'
import SelectBackground from '../../components/SelectFile/SelectBackground'

function ChooseFile({
  category,
  clearState,
  addTitle,
  addedTitle,
  addFullName,
}) {
  const history = useHistory()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalBackgroundIsOpen, setModalBackgroundIsOpen] = useState(false)
  const inputRef = useRef()

  const closeModal = () => {
    setModalIsOpen(false)
    setModalBackgroundIsOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      fullName: '',
      title: '',
    },
    validationSchema: categoryTitleSchema,
    onSubmit: (values) => {
      addTitle(values.title)
      clearState()
      addFullName(values.fullName)
      history.push(routes.chooseText)
    },
  })

  useEffect(() => {
    !category && history.push(routes.chooseCategory)
  }, [category, history])

  return (
    <CreatePost onNextButton={formik.handleSubmit}>
      <form className='createPost__form'>
        <h2 className='header--two createPost__header'>Category</h2>
        <input
          readOnly
          disabled
          type='text'
          className='createPost__input'
          defaultValue={category && category.name}
          style={{ marginBottom: '20px' }}
        />
        <div>
          <h2 className='header--two createPost__header'>Title</h2>
          <input
            ref={inputRef}
            type='text'
            required
            className={`createPost__input ${
              formik.errors.title && 'inputError'
            }`}
            name='title'
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ marginBottom: '20px' }}
            placeholder='Give new testimony a title...'
          />
          {formik.errors.title && (
            <p
              className='formNotification formNotification__error'
              style={{ marginTop: '10px' }}
            >
              {formik.errors.title}
            </p>
          )}
        </div>
        <div>
          <h2 className='header--two createPost__header'>Your name</h2>
          <input
            type='text'
            required
            className={`createPost__input ${
              formik.errors.fullName && 'inputError'
            }`}
            name='fullName'
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Give new testimony a name...'
          />

          <p
            className={`formNotification ${
              formik.errors.fullName && 'formNotification__error'
            }`}
            style={{ marginTop: '10px' }}
          >
            {formik.errors.fullName
              ? formik.errors.fullName
              : 'Type your firstname, and one initial (eg. Hilda M)'}
          </p>
        </div>
      </form>
      <div className='createPost__filebox filebox'>
        <button
          className='filebox__button'
          type='button'
          onClick={() =>
            formik.isValid && formik.values.title && formik.values.fullName
              ? setModalIsOpen(true)
              : formik.handleSubmit()
          }
        >
          Upload media
        </button>
        <button
          className='selectBackground'
          type='button'
          onClick={() =>
            formik.values.title
              ? setModalBackgroundIsOpen(true)
              : inputRef.current.focus()
          }
        >
          Select background
        </button>
      </div>
      <SelectFile
        isOpen={modalIsOpen}
        onClose={closeModal}
        addValues={() => {
          addTitle(formik.values.title)
          addFullName(formik.values.fullName)
        }}
      />
      <SelectBackground
        title={formik.values.title}
        isOpen={modalBackgroundIsOpen}
        onClose={closeModal}
        addTitle={() => {
          addTitle(formik.values.title)
          addFullName(formik.values.fullName)
        }}
      />
    </CreatePost>
  )
}

const mapStateToProps = (state) => {
  return {
    category: postSelectors.getCategory(state),
    addedTitle: postSelectors.getTitle(state),
  }
}

export default connect(mapStateToProps, {
  clearState: postOperations.clearState,
  addTitle: postOperations.addTitle,
  addFullName: postOperations.addFullName,
})(ChooseFile)
