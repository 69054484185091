import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import mutedIcon from '../../assets/img/muted-icon.svg'
import unmutedIcon from '../../assets/img/unmuted-icon.svg'
import ClipLoader from 'react-spinners/ClipLoader'

export default function Video({ isVisible = true, file, categoryName }) {
  useEffect(() => {}, [isVisible])
  const [muted, setMuted] = useState(true)
  const [loading, setLoading] = useState(true)
  const handleClick = () => {
    muted ? setMuted(false) : setMuted(true)
  }
  return (
    <div
      className={
        categoryName == 'Livestreams'
          ? `previewStreams previewStreams__video`
          : `preview preview__video`
      }
      onClick={handleClick}
    >
      <ReactPlayer
        playing={isVisible}
        url={file}
        muted={muted}
        loop={true}
        onReady={() => setLoading(false)}
      />
      <ClipLoader color={'#050138'} loading={loading} size={25} />
      <img
        className="preview__video-icon"
        src={muted ? mutedIcon : unmutedIcon}
        alt="speaker"
      />
    </div>
  )
}
