import { connect } from 'react-redux'
import React from 'react'
import { Post } from '../../components'
import authSelectors from '../../redux/auth/authSelectors'
import getDifferenceBetweenDates from '../../utils/getPeriodBetweenDates'
import { getMediaFile } from '../../utils/getMediaFile'

function PostsList({
  posts,
  userId,
  userFullName,
  activePost,
  handleActivePost,
  activeComment,
  handleActiveComment,
  handleDeletePostModalOpen,
  stopWords = null,
  handlePublishPost,
}) {
  return (
    <div className='home__wrap-block'>
      {posts.map((post) => {
        let dateAgo = getDifferenceBetweenDates(
          new Date(Date.now()),
          new Date(post.created_at)
        )
        const { url, type, isDefaultBackground, customClass } = getMediaFile({
          ...post?.media?.[0],
          style: post?.media?.[0]?.style,
        })
        return (
          <Post
            key={post.id}
            userStatus={post.user ? post.user.status : 0}
            stopWords={stopWords}
            activePost={post.id === activePost}
            handleActivePost={handleActivePost}
            activeComment={activeComment}
            handleActiveComment={handleActiveComment}
            handleDeletePostModalOpen={handleDeletePostModalOpen}
            handlePublishPost={handlePublishPost}
            post={post}
            isMyProfile={post.userId === userId}
            dateAgo={dateAgo}
            fileUrl={url}
            userAvatar={post.user?.media?.[0]?.url || ''}
            defaultImageClass={'defaultImage'}
            comments={post.comments}
            likes_count={post.likes_count}
            is_like_exists={post.is_like_exists}
            currentUserId={userId}
            currentUserFullName={userFullName}
            postFullName={post.user ? post.user.fullName : post.fullName}
            postUserName={post.user ? post.user.userName : post.fullName}
            fileType={type}
            fileClass={customClass}
            isDefaultBackground={isDefaultBackground}
            postCategory={post.category}
          />
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userFullName: authSelectors.getFullName(state),
  }
}

export default connect(mapStateToProps)(PostsList)
