import React, { useState } from 'react'
import './Table.scss'
import UserStatus from '../../components/Status/UserStatus'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import delelteIcon from '../../assets/img/admin/Delete.svg'

export default function UsersTable({ data, page, handleDeleteModalOpen }) {
  const [showModal, setShowModal] = useState(null)

  const handleShowModal = (id) => {
    setShowModal(id)
  }

  return (
    <div>
      <table className='table usersTable'>
        <thead>
          <tr className='table__headers'>
            <th>#</th>
            <th>Username</th>
            <th>Full Name</th>
            <th>Email</th>
            <th className='usersTable__posts'>Number of posts</th>
            <th style={{ textAlign: 'center' }}>Status</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element, i) => {
            return (
              <tr key={element.id}>
                <td>{i + 1 + 20 * (page - 1)}</td>

                <td className='text text--bold usersTable__name'>
                  <Link
                    to={routes.userPreview + '/' + element.id}
                    className='usersTable__link'
                  >
                    <Avatar
                      size={20}
                      name={element.fullName}
                      round='50%'
                      src={element?.media?.[0]?.url}
                      maxInitials={2}
                      color='#2E8299'
                    />
                    <span style={{ marginLeft: '8px' }}>
                      {element.userName}
                    </span>
                  </Link>
                </td>

                <td className='text text--bold usersTable__name'>
                  <Link
                    to={routes.userPreview + '/' + element.id}
                    className='usersTable__link'
                  >
                    {element.fullName}
                  </Link>
                </td>

                <td className='text text--bold usersTable__email'>
                  {element.email}
                </td>

                <td className='text text--bold usersTable__posts'>
                  {element.albums_count}
                </td>

                <td className='table__action'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <UserStatus
                      statusCode={element.status}
                      onShow={() => handleShowModal(element.id)}
                      show={showModal === element.id}
                      onClose={() => setShowModal(null)}
                      id={element.id}
                    />
                  </div>
                </td>
                <td className='table__action'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='table__action-button'
                      onClick={() => handleDeleteModalOpen(element.id)}
                    >
                      <img src={delelteIcon} alt='delete' />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
