import React from "react";
import "./Navigation.scss";
import { useHistory } from "react-router-dom";

export default function Navigation({ prevPage, currentPage, prevRoute }) {
  const history = useHistory();

  return (
    <div className="navigation">
      <span
        className="header header--four navigation__prevPage"
        onClick={() => history.push(prevRoute)}
      >
        {prevPage} {"< "}
      </span>
      <span className="header header--two navigation__currentPage">
        {" " + currentPage}
      </span>
    </div>
  );
}
