import React, { useState } from "react";
import Modal from "react-modal";
import "./Modal.scss";
import closeIcon from "../../assets/img/close-modal.svg";
import { useFormik } from "formik";
import changePasswordSchema from "../../validationSchemas/changePasswordSchema";
import { PasswordInput } from "../../components";
import axios from "axios";
import { api_url } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import routes from "../../routes";

Modal.setAppElement("#root");

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginLeft: 140 + "px",
    transform: "translate(-50%, -50%)",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0",
    paddingTop: "0",
    width: "568px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    overflow: "hidden",
  },
};

export default function ChangePassword({
  modalIsOpen,
  onCloseModal,
  onConfirm,
}) {
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ oldPassword: null });
  const [passwordHasChanged, setPasswordHasChanged] = useState(false);

  const history = useHistory();

  const formError = (key) => {
    if (
      (formik.errors[key] && formik.values[key]) ||
      (formik.errors[key] && submited) ||
      (error[key] && submited)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("password", values.oldPassword);
      formData.append("newPassword", values.password);
      axios
        .post(`${api_url}/profile/update-password`, formData)
        .then((response) => {
          setLoading(false);
          setPasswordHasChanged(true);
        })
        .catch((err) => {
          setError({ oldPassword: err.response.data.message });
          setLoading(false);
        });
    },
  });

  const handleCloseModal = () => {
    if (passwordHasChanged) {
      history.push(routes.logIn);
    }
    setSubmited(false);
    setPasswordHasChanged(false);
    onCloseModal();
    formik.resetForm();
    setError({ oldPassword: null });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
    >
      <>
        <div className="modal__top changePassword__top">
          <p className="text text--normal text--bold modal__header">
            {passwordHasChanged ? "Password has changed!" : "Change Password"}
          </p>
          <button onClick={handleCloseModal} className="modal__close">
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        {(values) => {
          formik.handleSubmit(values);
          setSubmited(true);
        }}
        <form
          className="form changePassword__form"
          onSubmit={(values) => {
            setSubmited(true);
            formik.handleSubmit(values);
          }}
        >
          <div className="form__group changePassword__form-group">
            <PasswordInput
              eyeColor="dark"
              className={`formInput password-input changePassword__formInput ${
                formError("oldPassword") && "inputError"
              }`}
              type="password"
              placeholder="Old Password"
              name="oldPassword"
              onChange={(e) => {
                formik.handleChange(e);
                setError({ oldPassword: null });
              }}
              onBlur={formik.handleBlur}
              value={formik.values.oldPassword}
              disabled={passwordHasChanged}
            />
            <p className="formNotification">
              {formError("oldPassword")
                ? formik.errors.oldPassword || error.oldPassword
                : "Enter the current password you are using to log into your account."}
            </p>
          </div>
          <div className="form__group changePassword__form-group">
            <PasswordInput
              eyeColor="dark"
              className={`formInput password-input changePassword__formInput ${
                formError("password") && "inputError"
              }`}
              type="password"
              placeholder="New Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              disabled={passwordHasChanged}
            />
            <p className="formNotification">
              {formError("password")
                ? formik.errors.password
                : "Create a strong password which has at least 8 symbols, both letters and numbers."}
            </p>
          </div>
          <div className="form__group changePassword__form-group">
            <PasswordInput
              eyeColor="dark"
              className={`formInput password-input changePassword__formInput ${
                formError("confirmPassword") && "inputError"
              }`}
              type="password"
              placeholder="Confirm New Password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              disabled={passwordHasChanged}
            />
            <p className="formNotification">
              {formError("confirmPassword")
                ? formik.errors.confirmPassword
                : " Confirm the password you entered above."}
            </p>
          </div>
          <div className="modal__bottom">
            <button
              className="link link--small modal__cancel"
              onClick={handleCloseModal}
            >
              {passwordHasChanged ? "OK" : "Cancel"}
            </button>

            {!passwordHasChanged && (
              <button
                type="submit"
                className="link link--small modal__ok"
                onClick={onConfirm}
              >
                {loading ? (
                  <ClipLoader color={"#050138"} loading={true} size={15} />
                ) : (
                  "Save"
                )}
              </button>
            )}
          </div>
        </form>
      </>
    </Modal>
  );
}
