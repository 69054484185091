import * as Yup from "yup";

import values from "./schemaValues";

const { addsName, addsDescription, addsUrl } = values;

const createAddSchema = Yup.object({
  addsName,
  addsDescription,
  addsUrl,
});

export default createAddSchema;
