import React, { useState } from 'react'
import Modal from 'react-modal'
import './selectFile.scss'
import { useHistory } from 'react-router-dom'
import routes from '../../routes'
import { connect } from 'react-redux'
import postSelectors from '../../redux/post/postSelectors'
import postOperations from '../../redux/post/postOperations'
import closeIcon from '../../assets/img/close-modal.svg'
import selected from '../../assets/img/selected.svg'
import { Button } from '../../components'

function SelectBackground({
  isOpen,
  onClose,
  addFile,
  addTitle,
  addBackground,
  title,
  clearState,
  getBackground,
  getBackgroundList,
}) {
  const history = useHistory()
  const [active, setActive] = useState(getBackground || 1)

  const handleGoNext = () => {
    addTitle(title)
    clearState()
    addBackground(active)
      history.push(routes.chooseText)
  }

  return (
    <Modal
      isOpen={isOpen}
      // style={customStyles}
      className='selectBackgroundFile'
      onRequestClose={onClose}
      bodyOpenClassName='selectFile__bodyOpen'
    >
      <button onClick={onClose} className='modal__close'>
        <img src={closeIcon} alt='close' />
      </button>
      <p className='header--three selectFile__text'>Select background</p>
      <div className='selectBackgroundFile__content'>
        {getBackgroundList.map(({ url, background_id }) => (
          <div
            key={background_id}
            onClick={() => setActive(background_id)}
            className={`selectBackgroundFile__card ${
              active === background_id
                ? 'selectBackgroundFile__card-active'
                : ''
            }`}
            style={{ backgroundImage: `url(${url})` }}
          >
            <span
              className='titleCardBackground'
            >
              {title}
            </span>
            {active === background_id && <img alt='' src={selected} />}
          </div>
        ))}
      </div>
      <div className='createPost__buttons' style={{marginTop: 10, flexDirection: 'row', flexWrap: 'wrap',}}>
        <Button
          text='Back'
          onClick={onClose}
          className='button button--outlined createPost__cancel'
          contained={false}
        />
        <Button
          text='Next'
          className='button button--primary createPost__next'
          onClick={handleGoNext}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    getBackground: postSelectors.getBackground(state),
    getBackgroundList: postSelectors.getBackgroundList(state),
  }
}

export default connect(mapStateToProps, {
  clearState: postOperations.clearState,
  addBackground: postOperations.addDefaultBackground,
})(SelectBackground)
