import React from "react";

export default function Status({ statusCode }) {
  return (
    <>
      {statusCode === 0 && (
        <span className="status status--pending">Pending</span>
      )}
      {statusCode === 1 && (
        <span className="status status--paused">Paused</span>
      )}
      {statusCode === 2 && (
        <span className="status status--running">Running</span>
      )}
      {statusCode === 3 && (
        <span className="status status--finished">Finished</span>
      )}
      {statusCode === 4 && (
        <span className="status status--stopped">Stopped</span>
      )}
    </>
  );
}
