import React, { useState } from "react";
import Modal from "react-modal";
import "./Modal.scss";
import closeIcon from "../../assets/img/close-modal.svg";
import copyIcon from "../../assets/img/copy-icon.svg";
import Tooltip from "./Tooltip";
import cutText from "../../utils/cutText";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
Modal.setAppElement("#root");

export default function ShareModal({ modalIsOpen, onCloseModal, link }) {
  const copyToBuffer = () => {
    setCopied(true);
    if (!copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
    navigator.clipboard.writeText(link);
  };

  const [copied, setCopied] = useState(false);

  return (
    <div className={`shareModal ${modalIsOpen && "shareModal--show"}`}>
      <p className="header header--four shareModal__header">Share</p>
      <button onClick={onCloseModal} className="shareModal__close">
        <img src={closeIcon} alt="close" />
      </button>
      <div className="shareModal__social">
        <FacebookShareButton url={link}>
          <FacebookIcon size={80} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={link}>
          <TwitterIcon size={80} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={link}>
          <WhatsappIcon size={80} round={true} />
        </WhatsappShareButton>
      </div>
      <span className="text text--normal shareModal__text">Share link</span>
      <div className="shareModal__buffer" onClick={copyToBuffer}>
        {cutText(link, 30)}
        <button className="shareModal__buffer-icon">
          <img
            src={copyIcon}
            alt="copy"
            className="shareModal__copyImage"
            data-tip="Copied!"
            data-event="click focus"
          />
          <Tooltip copied={copied} />
        </button>
      </div>
    </div>
  );
}
