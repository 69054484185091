import React from "react";
import { Route, Redirect } from "react-router-dom";
import authSelectors from "../redux/auth/authSelectors";
import { connect } from "react-redux";
import authOperations from "../redux/auth/authOperations";

function PrivateRoute({
  component: Component,
  redirectRoute,
  isLoggedIn,
  setPrevUrl,
  ...routeProps
}) {
  return (
    <Route
      {...routeProps}
      render={(props) => {
        !isLoggedIn && setPrevUrl(props.location.pathname);
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectRoute,
              state: { prevUrl: props.location.pathname },
            }}
          />
        );
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps, {
  setPrevUrl: authOperations.getPrevUrl,
})(PrivateRoute);
