import React, { createRef } from 'react'
import Main from './Main'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

const App = () => {
  const notistackRef = createRef()
  const onClickDismiss = (key) => () => notistackRef.current.closeSnackbar(key)
 
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          // TransitionComponent={Fade}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClickDismiss(key)}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          )}
          iconVariant={{
            success: <DoneIcon fontSize='small' />,
            error: <ClearIcon fontSize='small' />,
          }}
          autoHideDuration={205000}
        >
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
