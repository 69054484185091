import React, { useState, useEffect } from 'react'
import './post.scss'
import Avatar from 'react-avatar'

import { CommentsList, ModalWithButtons } from '../../components'
import routes from '../../routes'

import Description from './Description'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../config'
import checkQuantity from '../../utils/checkQuantity'

import ClipLoader from 'react-spinners/ClipLoader'
import expandIcon from '../../assets/img/admin/expand-icon.svg'
import favouritresIcon from '../../assets/img/admin/favourites-icon.svg'
import warnIcon from '../../assets/img/admin/warn-icon.svg'
import deleteIcon from '../../assets/img/admin/Delete.svg'
import favouritesActiveIcon from '../../assets/img/admin/favourite-active.svg'
import publishIcon from '../../assets/img/admin/publish.svg'
import closeIcon from '../../assets/img/close-modal.svg'
import pinIcon from '../../assets/img/admin/pin-icon.svg'
import { returnFilePost } from '../../utils/getMediaFile'

export default function Post({
  post,
  isMyProfile,
  userAvatar,
  dateAgo,
  fileUrl,
  fileClass,
  isDefaultBackground,
  defaultImageClass,
  currentUserId,
  handleActivePost,
  activePost,
  postFullName,
  postUserName,
  fileType,
  activeComment,
  handleActiveComment,
  handleDeletePostModalOpen,
  handlePublishPost,
  stopWords,
  userStatus,
}) {
  //eslint-disable-next-line
  const [commentsAreExpanded, setCommentAreExpanded] = useState(false)
  const [commentsData, setCommentsData] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [deleteCommentId, setDeleteCommentId] = useState(null)
  const [deleteCommentModalIsOpen, setDeleteCommentModalIsOpen] = useState(
    false,
  )
  const [adminFavourite, setAdminFavourite] = useState(post.admin_favorite)
  const [showPinHint, setShowPinHint] = useState(false)
  const [adminRecommended, setAdminRecommended] = useState(
    post.admin_recommended,
  )
  const [showWarnHint, setShowWarnHint] = useState(false)
  const [showCommentWarnHint, setShowCommentWarnHint] = useState(false)
  const [status, setStatus] = useState(userStatus)

  const location = useLocation()

  useEffect(() => {
    setShowWarnHint(false)
    setShowCommentWarnHint(false)
  }, [activeComment, activePost])

  const toggleAdminFavourite = () => {
    if (adminFavourite === 1) {
      setAdminFavourite(0)
      axios
        .post(`${api_url}/admin/albums/admin-favorite`, {
          id: post.id,
          admin_favorite: 0,
        })
        .then((response) => console.log(response.data))
    } else {
      setAdminFavourite(1)
      axios
        .post(`${api_url}/admin/albums/admin-favorite`, {
          id: post.id,
          admin_favorite: 1,
        })
        .then((response) => console.log(response.data))
    }
  }

  useEffect(() => {
    setCommentsLoading(true)
    axios
      .get(
        `${api_url}/comment?album_id=${post.id}&page=${page}&denied=${
          location.pathname === routes.posts + '/needReview' ? 1 : 0
        }`,
      )
      .then((response) => {
        setTotal(response.data.comments.total)
        setNextPageUrl(response.data.comments.next_page_url)
        setCommentsData(response.data.comments.data)
        setCommentsLoading(false)
      })
  }, [post, page, location])

  const handleDeleteCommentModalClose = () => {
    setDeleteCommentModalIsOpen(false)
  }

  // eslint-disable-next-line
  const handleDeleteCommentModalOpen = (id) => {
    setDeleteCommentId(id)
    setDeleteCommentModalIsOpen(true)
  }

  const handleDeleteComment = () => {
    setCommentsData((prevState) =>
      prevState.filter((comment) => comment.id !== deleteCommentId),
    )
    setTotal((prevState) => prevState - 1)
    axios
      .post(`${api_url}/admin/comments/deleted`, {
        id: deleteCommentId,
        is_deleted: true,
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err))
    handleDeleteCommentModalClose()
  }

  const handleExpandComments = () => {
    commentsAreExpanded
      ? setCommentAreExpanded(false)
      : setCommentAreExpanded(true)
  }

  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1)
    }
  }

  const handlePinPost = (time) => {
    if (!adminRecommended) {
      setShowPinHint(false)
      setAdminRecommended(1)

      axios
        .post(`${api_url}/admin/albums/recommended`, {
          id: post.id,
          recommended: 1,
          pin_due_date: time,
        })
        .then((response) => console.log(response.data))
    } else {
      setAdminRecommended(0)

      axios
        .post(`${api_url}/admin/albums/recommended`, {
          id: post.id,
          recommended: 0,
          pin_due_date: 0,
        })
        .then((response) => console.log(response.data))
    }
  }

  const handleWarnUser = (status) => {
    setShowWarnHint(false)
    axios
      .post(`${api_url}/admin/users/edit`, {
        id: post.userId,
        status,
      })
      .then((response) => console.log(response.data))
  }

  const handlePublishComment = (id) => {
    axios.post(`${api_url}/admin/comments/change-status`, {
      id,
      status: 1,
    })
  }

  return (
    <div
      className={`post__container ${
        location.pathname === routes.posts + '/favourites' &&
        !adminFavourite &&
        'display-none'
      }`}
    >
      {post ? (
        <div
          className={`post__wrap ${activePost && 'post__wrap--active'}`}
          onClick={() => handleActivePost(post.id)}
        >
          <div className="post__block">
            {post.userId ? (
              <Link
                className="post__user"
                to={routes.userPreview + '/' + post.userId}
              >
                <Avatar
                  size={32}
                  name={postFullName}
                  round="50%"
                  src={userAvatar}
                  maxInitials={2}
                  color="#2E8299"
                />
                <p className="text text--small text--bold post__user-fullName">
                  {postUserName}
                </p>
              </Link>
            ) : (
              <div className="post__user">
                <Avatar
                  size={32}
                  name={postFullName}
                  round="50%"
                  src={userAvatar}
                  maxInitials={2}
                  color="#2E8299"
                />
                <p className="text text--small text--bold post__user-fullName">
                  {postUserName}
                </p>
              </div>
            )}

            <p className="post__date text text--small">{dateAgo} ago</p>
          </div>

          <div style={{ position: 'relative' }}>
            {post.admin_recommended === 1 && (
              <p className="text text--bold post__category-admin">
                Editor's choice
              </p>
            )}
            {returnFilePost(
              fileType,
              fileUrl,
              isDefaultBackground,
              defaultImageClass,
              post?.title,
              post.admin_recommended,
              post?.category.name,
              fileClass,
            )}

            <div className="post__category">
              <span className="comments__likes text--small text--bold text">
                {checkQuantity(post.likes_count, 'like')}
              </span>

              <p className="header header--three post__category-title">
                {post.title}
              </p>

              {post.category && (
                <div className="post__category-name">
                  <span className="post__category-link">
                    {post.category.name}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="comments post__comments">
            {post.category && (
              <Description
                postId={post.id}
                categoryId={post.category.id}
                name={postFullName}
                text={post.description ? post.description : 'No description'}
                isMyProfile={isMyProfile}
                userId={post.userId}
                stopWords={stopWords}
              />
            )}
          </div>
          {activePost && (
            <div className="post__actions">
              {(location.pathname === routes.posts + '/feedView' ||
                location.pathname === routes.posts + '/favourites') && (
                <div className="post__actions-block">
                  <button
                    className="post__actions-button"
                    onClick={toggleAdminFavourite}
                  >
                    <img
                      src={
                        adminFavourite ? favouritesActiveIcon : favouritresIcon
                      }
                      alt="favourites"
                    />
                  </button>
                  <span className="post__actions-text text--bold link text--tiny">
                    {adminFavourite
                      ? 'remove from favorite'
                      : 'add to favorite'}
                  </span>
                </div>
              )}

              {(location.pathname === routes.posts + '/feedView' ||
                location.pathname === routes.posts + '/needReview') && (
                <>
                  {userStatus ? (
                    <div className="post__actions-block">
                      <button
                        className="post__actions-button"
                        onClick={() => setShowWarnHint(true)}
                      >
                        <img src={warnIcon} alt="warn" />
                      </button>
                      <span className="post__actions-text text--bold link text--tiny">
                        warn/mute/ban
                      </span>
                      {showWarnHint && (
                        <div className="post__actions-hint">
                          <button
                            className="post__actions-hintClose"
                            onClick={() => setShowWarnHint(false)}
                          >
                            <img src={closeIcon} alt="close" />
                          </button>
                          {status === 1 && (
                            <span
                              className="post__actions-hintElement text text--normal"
                              onClick={() => {
                                handleWarnUser(3)
                                setStatus(3)
                              }}
                            >
                              Warn
                            </span>
                          )}
                          {status === 3 && (
                            <span
                              className="post__actions-hintElement text text--normal"
                              onClick={() => {
                                handleWarnUser(4)
                                setStatus(4)
                              }}
                            >
                              Mute
                            </span>
                          )}
                          {status === 4 && (
                            <span
                              className="post__actions-hintElement text text--normal"
                              onClick={() => {
                                handleWarnUser(2)
                                setStatus(2)
                              }}
                            >
                              Ban
                            </span>
                          )}
                          {status === 2 && (
                            <span
                              className="post__actions-hintElement text text--normal"
                              onClick={() => {
                                handleWarnUser(1)
                                setStatus(1)
                              }}
                            >
                              Activate
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {location.pathname === routes.posts + '/needReview' &&
                    parseInt(post.status) === 3 && (
                      <div className="post__actions-block">
                        <button
                          className="post__actions-button"
                          onClick={() => {
                            handlePublishPost(post.id)
                          }}
                        >
                          <img src={publishIcon} alt="publish" />
                        </button>
                        <span className="post__actions-text text--bold link text--tiny">
                          publish post
                        </span>
                      </div>
                    )}

                  <div className="post__actions-block">
                    <button
                      className="post__actions-button"
                      onClick={() => handleDeletePostModalOpen(post.id)}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </button>
                    <span className="post__actions-text text--bold link text--tiny">
                      delete
                    </span>
                  </div>
                </>
              )}
              {location.pathname === routes.posts + '/favourites' && (
                <div className="post__actions-block">
                  <button
                    className="post__actions-button"
                    onClick={() => {
                      adminRecommended ? handlePinPost() : setShowPinHint(true)
                    }}
                  >
                    <img src={pinIcon} alt="pin" />
                  </button>
                  <span className="post__actions-text text--bold link text--tiny">
                    {adminRecommended ? 'unpin' : 'pin'} post
                  </span>
                  {showPinHint && (
                    <div className="post__actions-hint">
                      <button
                        className="post__actions-hintClose"
                        onClick={() => setShowPinHint(false)}
                      >
                        <img src={closeIcon} alt="close" />
                      </button>
                      <span
                        className="post__actions-hintElement text text--normal"
                        onClick={() => handlePinPost(3 * 60)}
                      >
                        Pin for 3 hours
                      </span>
                      <span
                        className="post__actions-hintElement text text--normal"
                        onClick={() => handlePinPost(6 * 60)}
                      >
                        Pin for 6 hours
                      </span>
                      <span
                        className="post__actions-hintElement text text--normal"
                        onClick={() => handlePinPost(24 * 60)}
                      >
                        Pin for 1 day
                      </span>
                      <span
                        className="post__actions-hintElement text text--normal"
                        onClick={() => handlePinPost(48 * 60)}
                      >
                        Pin for 2 days
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '50px',
          }}
        >
          <ClipLoader color={'#050138'} loading={true} size={70} />
        </div>
      )}

      <div className="post__bottomComments">
        <div>
          <hr />
          {commentsData.length > 0 ? (
            <>
              <button
                className="post__bottomComments-button"
                onClick={handleExpandComments}
              >
                <span className="link text--small">
                  {commentsAreExpanded ? 'Hide' : 'Read'}{' '}
                  {checkQuantity(total, 'comment')}
                </span>
                <div
                  className={`post__expand-button ${
                    commentsAreExpanded && 'post__expand-button--active'
                  }`}
                >
                  <img src={expandIcon} alt="expand" />
                </div>
              </button>
              {commentsAreExpanded && (
                <>
                  <div>
                    <ClipLoader
                      color={'#050138'}
                      loading={commentsLoading}
                      size={25}
                    />
                  </div>
                  {!commentsLoading && (
                    <CommentsList
                      comments={commentsData}
                      currentUserId={currentUserId}
                      isMyProfile={isMyProfile}
                      handleDeleteComment={handleDeleteComment}
                      handleOpenModal={handleDeleteCommentModalOpen}
                      handleActiveComment={handleActiveComment}
                      activeComment={activeComment}
                      showWarnHint={showCommentWarnHint}
                      openWarnHint={() => setShowCommentWarnHint(true)}
                      closeWarnHint={() => setShowCommentWarnHint(false)}
                      handleWarnUser={handleWarnUser}
                      stopWords={stopWords}
                      handleUserStatus={(status) => {
                        setStatus(status)
                        handleWarnUser(status)
                      }}
                      userStatus={status}
                      handlePublishComment={handlePublishComment}
                    />
                  )}

                  <div
                    className="adds__pagination"
                    style={
                      {
                        // justifyContent: "flex-start",
                        // paddingLeft: "47px",
                      }
                    }
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {page > 1 ? 10 * (page - 1) + 1 : 1}-{10 * page} of{' '}
                      {total} Items
                      <button
                        type="button"
                        className={`adds__pagination-button ${
                          page < 2 && 'disabled'
                        }`}
                        onClick={handlePrevPage}
                      >
                        {'<'}
                      </button>
                      <button
                        type="button"
                        className={`adds__pagination-button ${
                          !nextPageUrl && 'disabled'
                        }`}
                        onClick={handleNextPage}
                      >
                        {'>'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <span>No comments</span>
          )}
        </div>
      </div>

      <ModalWithButtons
        modalIsOpen={deleteCommentModalIsOpen}
        onCloseModal={handleDeleteCommentModalClose}
        header="Delete Comment"
        text="Are you sure you want to delete a comment? This action cannot be reverted."
        onConfirm={handleDeleteComment}
      />
    </div>
  )
}
