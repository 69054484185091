const getCategory = (state) => state.post.category;

const getFile = (state) => state.post.file;

const getStyle = (state) => state.post.style;

const getTitle = (state) => state.post.title;

const getBackground = (state) => state?.post?.background;

const getFullName = (state) => state.post.fullName;

const getStopWords = (state) =>
  state.post.stopWords && state.post.stopWords.words;

const getBackgroundList = (state) =>
    state?.post?.backgroundsList || [];

const postSelectors = {
  getCategory,
  getFile,
  getStyle,
  getStopWords,
  getTitle,
  getFullName,
  getBackground,
  getBackgroundList
};

export default postSelectors;
