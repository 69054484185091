import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import authSelectors from "../redux/auth/authSelectors";

function PublicRoute({
  component: Component,
  redirect,
  isLoggedIn,
  ...routeProps
}) {
  return (
    <Route
      {...routeProps}
      render={(props) => {
        return isLoggedIn ? (
          <Redirect to={redirect} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: authSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps)(PublicRoute);
