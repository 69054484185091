import { createReducer } from "@reduxjs/toolkit";
import postActions from "./postActions";
import { combineReducers } from "redux";

const category = createReducer(null, {
  [postActions.addCategory]: (_, { payload }) => payload,
});

const file = createReducer(null, {
  [postActions.addFile]: (_, { payload }) => payload,
  [postActions.clearState]: () => null,
});

const text = createReducer(null, {
  [postActions.addText]: (_, { payload }) => payload,
});

const title = createReducer(null, {
  [postActions.addTitle]: (_, { payload }) => payload,
});

const fullName = createReducer(null, {
  [postActions.addFullName]: (_, { payload }) => payload,
});

const style = createReducer(null, {
  [postActions.addStyle]: (_, { payload }) => payload,
  [postActions.addFile]: () => null,
});

const stopWords = createReducer(null, {
  [postActions.getStopWords]: (_, { payload }) => payload,
});

const background = createReducer(null, {
  [postActions.addDefaultBackground]: (_, { payload }) => payload,
  [postActions.clearBackground]: () => null,
});

const backgroundsList = createReducer(null, {
  [postActions.backgroundsList]: (_, { payload }) => payload,
});

export default combineReducers({
  category,
  file,
  text,
  title,
  style,
  stopWords,
  fullName,
  background,
  backgroundsList
});
