import React from "react";
import "./Table.scss";

import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { StatusButtons } from "../../components";

export default function AddsTable({ data, handleDeleteModalOpen, page }) {
  const history = useHistory();
  console.log("data", data);
  return (
    <div>
      <table className="table">
        <thead>
          <tr className="table__headers">
            <th>#</th>
            <th>Asset</th>
            <th>Ads name</th>
            <th>
              Date of Creation <br />
              (mm/dd/yyyy)
            </th>
            <th>Number <br/> of views</th>
            <th>Number <br/> of clicks</th>
            <th>Conversion Rate</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element, i) => {
            return (
              <tr key={element.id}>
                <td>{i + 1 + 20 * (page - 1)} </td>
                <td>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      margin: "0 auto",
                    }}
                  >
                    <img
                      src={element?.media?.[0]?.url}
                      alt="asset"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </td>
                <td className="table__adsName">
                  <button
                    onClick={() => {
                      history.push({
                        pathname: routes.editAdd + "/" + element.id,
                        state: {
                          id: element.id,
                          title: element.title,
                          description: element.description,
                          status: element.status,
                          date: new Date(element.created_at).toLocaleDateString(
                            "en-US"
                          ),
                          conversion_rate: element.conversion_rate,
                          url: element.url,
                          file: element?.media?.[0]?.url,
                        },
                      });
                    }}
                  >
                    {element.title}
                  </button>
                </td>
                <td>
                  {new Date(element.created_at).toLocaleDateString("en-US")}
                </td>
                <td>
                  {element.views}
                </td>
                <td>
                  {element.clicks}
                </td>
                <td>{element.conversion_rate}%</td>
                <StatusButtons
                  status={element.status}
                  id={element.id}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
