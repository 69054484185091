import * as Yup from "yup";

import values from "./schemaValues";

const { password, confirmPassword } = values;

const changePasswordSchema = Yup.object({
  oldPassword: password,
  password,
  confirmPassword,
});

export default changePasswordSchema;
