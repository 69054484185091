import React, { useState, useEffect } from "react";
import "./Status.scss";
import closeIcon from "../../assets/img/close-modal.svg";
import axios from "axios";
import { api_url } from "../../config";

export default function UserStatus({ statusCode, onShow, show, onClose, id }) {
  const [status, setStatus] = useState(statusCode);
  // const [toggled, setToggled] = useState(false);
  const [showModal, setShowModal] = useState(show);
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const toggleStatus = (statusNumber) => {
    setShowModal(false);
    if (statusNumber !== status) {
      setStatus(statusNumber);
      axios
        .post(`${api_url}/admin/users/edit`, { id, status: statusNumber })
        .then((response) => console.log(response.data));
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {status === 1 && (
        <button
          onClick={() => {
            setShowModal(true);
            onShow();
          }}
          className="status status--running userStatus__status"
        >
          Active
        </button>
      )}
      {status === 2 && (
        <button
          onClick={() => {
            setShowModal(true);
            onShow();
          }}
          className="status status--stopped userStatus__status"
        >
          Blocked
        </button>
      )}
      {status === 3 && (
        <button
          onClick={() => {
            setShowModal(true);
            onShow();
          }}
          className="status status--paused userStatus__status"
        >
          Warned
        </button>
      )}
      {status === 4 && (
        <button
          onClick={() => {
            setShowModal(true);
            onShow();
          }}
          className="status status--finished userStatus__status"
        >
          Muted
        </button>
      )}
      {showModal && (
        <div className="userStatus__modal">
          <button
            className="userStatus__modal-close"
            type="button"
            onClick={() => {
              setShowModal(false);
              onClose();
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
          {status !== 1 && (
            <div
              className="userStatus__modal-block"
              onClick={() => toggleStatus(1)}
            >
              <span className="status status--running">Activate</span>
            </div>
          )}
          {status === 1 && (
            <div
              className="userStatus__modal-block"
              onClick={() => toggleStatus(3)}
            >
              <span className="status status--paused">Warn</span>
            </div>
          )}
          {status === 3 && (
            <div
              className="userStatus__modal-block"
              onClick={() => toggleStatus(4)}
            >
              <span className="status status--finished">Mute</span>
            </div>
          )}
          {status === 4 && (
            <div
              className="userStatus__modal-block"
              onClick={() => toggleStatus(2)}
            >
              <span className="status status--stopped">Block</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
