import checkQuantity from "./checkQuantity";

export default function getDifferenceBetweenDates(date1, date2) {
  // const hours = getDifferenceInHours(date1, date2);
  const minutes = getDifferenceInMinutes(date1, date2);
  let time = null;
  let timeString = "";
  switch (true) {
    case minutes > 60 * 24 * 365:
      time = Math.ceil(minutes / (60 * 24 * 30 * 365));
      timeString = checkQuantity(time, "year");
      break;
    case minutes > 60 * 24 * 30:
      time = Math.ceil(minutes / (60 * 24 * 30));
      timeString = checkQuantity(time, "month");
      break;
    case minutes > 60 * 24:
      time = Math.ceil(minutes / (60 * 24));
      timeString = checkQuantity(time, "day");
      break;
    case minutes > 60:
      time = Math.ceil(minutes / 60);
      timeString = checkQuantity(time, "hour");
      break;
    default:
      time = Math.ceil(minutes);
      timeString = checkQuantity(time, "minute");
      break;
  }

  return timeString;
}

function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}
