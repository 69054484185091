import { createAction } from "@reduxjs/toolkit";

const registerRequest = createAction("auth/registerRequest");
const registerSuccess = createAction("auth/registerSuccess");
const registerError = createAction("auth/registerError");

const loginRequest = createAction("auth/loginRequest");
const loginSuccess = createAction("auth/loginSuccess");
const loginError = createAction("auth/loginError");

const rememberMeLogInRequest = createAction("auth/rememberMeLogInRequest");
const rememberMeLogInSuccess = createAction("auth/rememberMeLogInSuccess");
const rememberMeLogInError = createAction("auth/rememberMeLogInError");

const logOutRequest = createAction("auth/logOutRequest");
const logOutSuccess = createAction("auth/logOutSuccess");
const logOutError = createAction("auth/logOutError");

const getCurrentUserRequest = createAction("auth/getCurrentUserRequest");
const getCurrentUserSuccess = createAction("auth/getCurrentUserSuccess");
const getCurrentUserError = createAction("auth/getCurrentUserError");

const getUserFollowersSuccess = createAction("auth/getUserFollowers");
const getUserFollowersError = createAction("auth/getUserFollowersError");

const getAvatarRequest = createAction("auth/getAvatarRequest");
const getAvatarSuccess = createAction("auth/getAvatarSuccess");
const getAvatarError = createAction("auth/getAvatarError");

const getPrevUrl = createAction("auth/getPrevUrl");
const clearPrevUrl = createAction("auth/clearPrevUrl");

const clearErrors = createAction("auth/clearErrors");

const authActions = {
  registerRequest,
  registerSuccess,
  registerError,
  loginRequest,
  loginSuccess,
  loginError,
  logOutRequest,
  logOutSuccess,
  logOutError,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
  rememberMeLogInRequest,
  rememberMeLogInSuccess,
  rememberMeLogInError,
  getAvatarRequest,
  getAvatarSuccess,
  getAvatarError,
  clearErrors,
  getUserFollowersSuccess,
  getUserFollowersError,
  getPrevUrl,
  clearPrevUrl,
};

export default authActions;
