import React from "react";
import Logo from "../assets/img/logo.png";

export default function NotFound() {
  return (
    <div className="container">
      <div className="wrap" style={{ height: "80vh" }}>
        <img src={Logo} alt="logo" />
        <h1 className="header header--two" style={{ marginTop: "20px" }}>
          404 Page is Not Found
        </h1>
      </div>
    </div>
  );
}
